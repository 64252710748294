import { FC } from 'react';
import { CollectionsRus } from '@enum/enum';
import {
    ArrayInput,
    AutocompleteInput,
    FormDataConsumer,
    minValue,
    number,
    NumberInput,
    ReferenceInput,
    required,
    SimpleFormIterator
} from 'react-admin';
import { Product } from '@interfaces/entities';
import { TextField } from '@mui/material';

type TypeRalProps = {
    mode: 'field' | 'input'
};

export const productRenderer = (product: Product | null) => (
    product
        ? `${product.totalAmount} шт - ${product.name}`
        : '-'
);

const RalComponents: FC<TypeRalProps> = ({ mode, }) => {
    return (
        <>
            {
                mode === 'input' ? (
                    <ArrayInput
                        validate={(value) => (
                            Array.isArray(value) ? (
                                value.length > 0 ? '' : 'Должен быть выбран минимум 1 компонент'
                            ) : 'Выберите компоненты'
                        )}
                        source="components"
                        label="Компоненты"
                    >
                        <SimpleFormIterator>
                            <ReferenceInput
                                fullWidth
                                label="Товар"
                                source="productId"
                                reference={CollectionsRus.Products}
                                filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                                validate={[ required() ]}
                            >
                                <AutocompleteInput source='name' optionText={productRenderer} />
                            </ReferenceInput>

                            <NumberInput
                                fullWidth
                                label="Вес (кг) для получения 10 кг"
                                placeholder="Введите вес"
                                source="needWeightFor10KG"
                                step={0.001}
                                validate={[ required(), number(), minValue(0.001) ]}
                                onWheel={(e) => (e.target as any).blur()}
                            />

                            <FormDataConsumer>
                                {({ getSource, scopedFormData, ...rest }) => {
                                    const { needWeightFor10KG = 0, } = scopedFormData ?? {};
                                    const { formData: { koefWeightIn10L = 0, }, } = rest;

                                    return (
                                        <TextField
                                            label="Вес (кг) для получения 10 литров"
                                            placeholder="Введите вес"
                                            fullWidth
                                            disabled
                                            value={String(Number(needWeightFor10KG * koefWeightIn10L / 10).toFixed(3))}
                                        />
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                ) : <></>
            }
        </>
    );
};

export default RalComponents;