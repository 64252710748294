export enum Collections {
    Brands = 'brands',
    Categories = 'categories',
    Products = 'products',
    Consultation = 'consultation',
    Users = '_User',
    Blogs = 'blogs',
    BlogGroups = 'blog_groups',
    Orders = 'orders',
    BulkProductImages = 'bulk-product-images',
    BulkProductManuals = 'bulk-product-manuals',
    BulkProductDensity = 'bulk-product-density',
    Compilation = 'compilation',
    Logs = 'logs',
    RAL = 'RAL',
    RALOrder = 'RALOrder',
    Telegram = 'Telegram',
}

export enum CollectionsRus {
    Brands = 'Бренды',
    Categories = 'Категории',
    Products = 'Товары',
    Consultation = 'Консультации',
    Users = 'Пользователи',
    Blogs = 'Блоги',
    BlogGroups = 'Группы блогов',
    Orders = 'Заказы',
    BulkProductImages = 'Добавление фото товаров',
    BulkProductManuals = 'Добавление инструкций товаров',
    BulkProductDensity = 'Добавление плотности для товаров',
    Compilation = 'Подборки',
    Logs = 'Логи',
    RAL = 'Формулы Indomix',
    RALOrder = 'Рассчёт RAL',
    Telegram = 'Telegram',
}

export const MapResourceObject: Record<CollectionsRus, Collections> = {
    [CollectionsRus.Brands]: Collections.Brands,
    [CollectionsRus.Categories]: Collections.Categories,
    [CollectionsRus.Products]: Collections.Products,
    [CollectionsRus.Consultation]: Collections.Consultation,
    [CollectionsRus.Users]: Collections.Users,
    [CollectionsRus.Blogs]: Collections.Blogs,
    [CollectionsRus.BlogGroups]: Collections.BlogGroups,
    [CollectionsRus.Orders]: Collections.Orders,
    [CollectionsRus.BulkProductImages]: Collections.BulkProductImages,
    [CollectionsRus.BulkProductManuals]: Collections.BulkProductManuals,
    [CollectionsRus.BulkProductDensity]: Collections.BulkProductDensity,
    [CollectionsRus.Compilation]: Collections.Compilation,
    [CollectionsRus.Logs]: Collections.Logs,
    [CollectionsRus.RAL]: Collections.RAL,
    [CollectionsRus.RALOrder]: Collections.RALOrder,
    [CollectionsRus.Telegram]: Collections.Telegram,
};

export enum AdditionalImagePrefix {
    Thumbnail = 'thumbnail_',
    Icon = 'icon_',
    Webp = 'webp_',
}

export enum BlogIcons {
    wheel = 'wheel',
}