import { IndomixTypeEnum } from '@enum/ral';
import { SelectInputItem } from '@interfaces/global';

export const IndomixType: Record<IndomixTypeEnum, string> = {
    [IndomixTypeEnum.IndosinglA]: 'Грунт-эмаль Индосингл А',
    [IndomixTypeEnum.IndosinglPU50]: 'Грунт-эмаль Индосингл ПУ 50',
    [IndomixTypeEnum.IndosinglPU80]: 'Грунт-эмаль Индосингл ПУ 80',
    [IndomixTypeEnum.IndosinglEP]: 'Грунт-эмаль Индосингл ЭП',
    [IndomixTypeEnum.EmalIndopur]: 'Эмаль Индопур',
};

export const ListIndomixTypes: SelectInputItem<IndomixTypeEnum, string>[] = Object.entries(IndomixType)
    .map(([ key, value ]) => ({
        id: key as IndomixTypeEnum,
        name: value,
    }));

