import {
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    UpdateManyParams,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import Parse from 'parse';

import { Product } from '@interfaces/entities';
import { TypeBulkProducts, OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { ArrayOfUploadedPhotos, PhotoObject } from '@interfaces/file';
import { Collections } from '@enum/enum';
import { extractParamsFromUrl } from '@utils/workerWithUrl';
import awaiter from '@utils/awaiter';
import { mapArrayOfPhotoObjectsToArrayOfUploadedPhotos } from '@utils/index';
import ParseHelper from './ParseHelper';

export type ParamsCreateCategory = Pick<
Product,
'name'
| 'description'
| 'price'
| 'brand'
| 'totalAmount'
| 'bonusPercent'
| 'inStock'
| 'isOnSale'
| 'sizeX'
| 'sizeY'
| 'sizeZ'
| 'weight'
| 'categoryId'
| 'category_id_1c'
| 'brand_id_1c'
| 'metaDescription'
| 'seoTitle'
| 'seoHeader'
| 'seoDescription'
| 'photos'
| 'sku'
| 'id_1c'
> & {
  photos: ArrayOfUploadedPhotos,
};

const attributes: Array<keyof Product> = [
    'id_1c',
    'id',
    'name',
    'breadcrumbs',
    'photos',
    'slug',
    'translit',
    'metaDescription',
    'seoTitle',
    'seoHeader',
    'seoDescription',
    'isDeleted',
    'isPopular',
    'isDangerous',
    'price',
    'totalAmount',
    'bonusPercent',
    'inStock',
    'description',
    'manual',
    'useful',
    'recommendation',
    'sizeX',
    'sizeY',
    'sizeZ',
    'weight',
    'brandId',
    'brand',
    'isOnSale',
    'arrayOfParentCategories',
    'sku',
    'categoryId',
    'updatedAt',
    'density',
    'singleWeight',
    'totalResidueWeight'
];

export type OverridedProduct = Omit<Product, 'photos'> & { photos: ArrayOfUploadedPhotos };

@staticImplements<Pick<OverrideDataProvider<Product>, 'getList' | 'getOne' | 'create' | 'update' | 'delete' | 'deleteMany'>>()
export default class ProductsClass {
    static async getList(
        _: string,
        params: GetListParams
    ): Promise<GetListResult<Product>> {
        const { filter, pagination, sort, } = params;
        
        let productsQuery = new Parse.Query(Collections.Products);
        const { parentCategory, name, brandId, parentCategorySlug, } = filter ?? {};

        if (name !== undefined) {
            productsQuery = ParseHelper.GetQueryForSearch<Product>('name', name, productsQuery);
        }

        if (parentCategory !== undefined) {
            productsQuery = productsQuery.equalTo<keyof Product>('categoryId', parentCategory);
        }

        if (parentCategorySlug !== undefined) {
            productsQuery = productsQuery.containedIn<keyof Product>('arrayOfParentCategories', [ parentCategorySlug ]);
        }

        if (brandId !== undefined) {
            productsQuery = productsQuery.equalTo<keyof Product>('brandId', brandId);
        }

        productsQuery = ParseHelper.GetSortQuery(productsQuery, sort);

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            productsQuery,
            attributes
        );

        return result;
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Product>> {
        return ParseHelper.GetOneEntity(id, Collections.Products, attributes);
    }

    static async create(
        _: string,
        { data, }: CreateParams<ParamsCreateCategory>
    ): Promise<CreateResult<Product>> {
        const url = window.location.href;
        const parentCategory = extractParamsFromUrl(
            url.slice(url.indexOf('?')), 'refCategory'
        );

        if (parentCategory) {
            data.categoryId = parentCategory;
    
            const {
                parseObject,
                data: createdData,
                resultPhotosHaveLargeSize,
                filesHaveLargeSize,
            } = await ParseHelper.CreateObject(
                data,
                Collections.Products
            );

            parseObject.save();
    
            await awaiter(1000);

            return {
                data: createdData,
                resultPhotosHaveLargeSize,
                filesHaveLargeSize,
            } as any;
        }

        return {
            data: data as any,
            resultPhotosHaveLargeSize: [],
            filesHaveLargeSize: [],
        } as any;
    }

    static async update(
        _: string,
        { id, previousData, data, }: UpdateParams<OverridedProduct>
    ): Promise<UpdateResult<Product>> {
        const brandId = data.brandId ?? '';

        delete (data as any).brand;
        
        return await ParseHelper.UpdateDataForCategoryOrProduct<OverridedProduct, Product>(
            { ...data, brandId, },
            id,
            previousData,
            Collections.Products
        );
    }

    static async delete(
        _: string,
        {
            id, previousData,
        }: DeleteParams
    ): Promise<DeleteResult<Product>> {
        return ParseHelper.DeleteOne<Product>(id, Collections.Products, previousData);
    }

    static async deleteMany(
        _: string,
        { ids, }: DeleteManyParams
    ): Promise<DeleteManyResult> {
        return ParseHelper.DeleteMany<Product>(ids, Collections.Products, attributes);
    }
    
    static async uploadMany(
        _: string,
        params: UpdateManyParams
    ) {
        const { type, ...otherParams } = params as any;

        switch (type as TypeBulkProducts) {
        case 'images':
            return await ProductsClass.uploadImagesForProducts(_, otherParams);
        case 'file':
            return await ProductsClass.uploadFilesForProducts(_, otherParams);
        case 'density':
            return await ProductsClass.uploadDensityForProducts(_, otherParams);
        default:
            return otherParams;
        }
    }

    private static async uploadFilesForProducts(
        _: string,
        { data: { ids, manual, }, }: UpdateManyParams
    ) {
        const updatedProducts = await new Parse.Query(Collections.Products)
            .containedIn('objectId', ids)
            .findAll();

        let countSuccessUploaded = 0;
        let countFailUploaded = 0;

        const resultFilesHaveLargeSize: string[] = [];

        if (updatedProducts.length) {
            const [ updatedProduct, ...needUpdateProducts ] = updatedProducts;

            const originalPhotos = updatedProduct.get('photos') ?? [];
            const mappedPhotos = mapArrayOfPhotoObjectsToArrayOfUploadedPhotos(originalPhotos);

            const {
                filesHaveLargeSize,
                manual: manualLink,
            } = await ParseHelper.UpdateDataForCategoryOrProduct<OverridedProduct, Product>(
                { manual, photos: mappedPhotos, originalPhotos, } as any,
                updatedProduct.id,
                { originalPhotos, } as any,
                Collections.Products
            );

            countSuccessUploaded = !filesHaveLargeSize.length ? 1 : 0;
            countFailUploaded = filesHaveLargeSize.length;
            resultFilesHaveLargeSize.push(...filesHaveLargeSize);

            const manualFromEntity = manualLink;
            const savedProducts: Array<Parse.Object> = [];

            needUpdateProducts.forEach((handledProduct) => {
                handledProduct.set('manual', manualFromEntity);

                savedProducts.push(handledProduct);
            });

            await Parse.Object.saveAll(savedProducts);
        }

        return {
            data: { manual, id: '123', } as any,
            id: '123',
            countFailUploaded,
            countSuccessUploaded,
            resultPhotosHaveLargeSize: resultFilesHaveLargeSize,
        };
    }

    private static async uploadImagesForProducts(
        _: string,
        { data: { ids, photos, resetUseWatermark, }, }: UpdateManyParams
    ) {
        const updatedProducts = await new Parse.Query(Collections.Products)
            .containedIn('objectId', ids)
            .findAll();

        let countSuccessUploaded = 0;
        let countFailUploaded = 0;
        const resultPhotosHaveLargeSize: string[] = [];

        if (updatedProducts.length) {
            const [ updatedProduct, ...needUpdateProducts ] = updatedProducts;

            const originalPhotos = updatedProduct.get('photos') ?? [];
            const mappedPhotos = mapArrayOfPhotoObjectsToArrayOfUploadedPhotos(originalPhotos);
            const {
                uploadedPhotos,
                resultPhotosHaveLargeSize: photosHaveLargeSize,
            } = await ParseHelper.UpdateDataForCategoryOrProduct<OverridedProduct, Product>(
                { photos: [ ...photos, ...mappedPhotos ], originalPhotos, resetUseWatermark, } as any,
                updatedProduct.id,
                { originalPhotos, } as any,
                Collections.Products
            );

            countSuccessUploaded = uploadedPhotos.length;
            countFailUploaded = photosHaveLargeSize.length;
            resultPhotosHaveLargeSize.push(...photosHaveLargeSize);

            await awaiter(5000);

            const updatedProductFromServer = await ParseHelper.GetObject(updatedProduct.id, Collections.Products);

            let photosFromEntity = updatedProductFromServer.get('photos') as PhotoObject[];
            const idxReplacedOrderPhotos: Array<number> = [];

            const uploadedImages = uploadedPhotos.reduce((handledArrayImages, uploadedPhoto) => {
                const idxUploadedImage = photosFromEntity.findIndex(({ original, }) => original === uploadedPhoto);

                if (idxUploadedImage !== -1) {
                    handledArrayImages.push(photosFromEntity[idxUploadedImage]);
                    idxReplacedOrderPhotos.push(idxUploadedImage);
                }
                return handledArrayImages;
            }, [] as PhotoObject[]);

            const savedProducts: Array<Parse.Object> = [];

            photosFromEntity = photosFromEntity.filter((_, idx) => !idxReplacedOrderPhotos.includes(idx));
            updatedProduct.set('photos', [ ...photosFromEntity, ...uploadedImages ]);
            savedProducts.push(updatedProduct);

            needUpdateProducts.forEach((handledProduct) => {
                const currentPhotos = handledProduct.get('photos');

                handledProduct.set('photos', [ ...currentPhotos, ...uploadedImages ]);

                savedProducts.push(handledProduct);
            });

            await Parse.Object.saveAll(savedProducts);
        }

        return {
            data: { photos, id: '123', } as any,
            id: '123',
            countFailUploaded,
            countSuccessUploaded,
            resultPhotosHaveLargeSize,
        };
    }

    private static async uploadDensityForProducts(
        _: string,
        { data: { ids, density, }, }: UpdateManyParams
    ) {
        const updatedProducts = await new Parse.Query(Collections.Products)
            .containedIn('objectId', ids)
            .findAll();

        updatedProducts.forEach((updatedProduct) => {
            updatedProduct.set('density', density);
        });

        await Parse.Object.saveAll(updatedProducts);

        return {
            data: { density, id: '123', } as any,
            id: '123',
            isSuccess: true,
        };
    }

    static async getMany(
        _: string,
        { ids, }: GetManyParams
    ): Promise<GetManyResult<Product>> {
        return ParseHelper.GetManyEntities(ids, Collections.Products, attributes);
    }
}
