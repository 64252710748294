import { Collections } from '@enum/enum';
import ParseHelper from '@parse_entity_utils/ParseHelper';
import awaiter from '@utils/awaiter';

export const getCreateOrderInApiShip = async (id: string | undefined): Promise<boolean | string> => {
    try {
        if(!id){
            return 'Заказ не найден';
        }

        const orderQuery = await ParseHelper.GetObject(id, Collections.Orders);
        return orderQuery.get('createOrderInApiShip') ?? false;
    }catch(e){
        return 'Не удалось получить данные Api Ship';
    }
};

export const changeCreateOrderInApiShip = async (id: string | undefined, value: boolean): Promise<boolean | string> => {
    try {
        if (!id){
            return 'Заказ не найден';
        }

        await ParseHelper.UpdateObject(
            { createOrderInApiShip: value, },
            true,
            Collections.Orders,
            String(id)
        );

        await awaiter(10000);

        window.location.reload();
        return true;
    }catch(e){
        return 'Не удалось изменить данные';
    }
};
