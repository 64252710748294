import { CreateEntityWithConfirm } from '@components/common/confirmBtn';
import { CollectionsRus } from '@enum/enum';
import {
    ShowProps,
    Create,
    SimpleForm,
    required,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import CalcCostBtn from './calcCostBtn';

const transform = ({ disableConfirm, isLoading, ...data }: any) => (data);

const RALCreate = (props: Partial<ShowProps>) => (
    <Create
        transform={transform}
        {...props}
    >
        <SimpleForm
            toolbar={(
                <CreateEntityWithConfirm
                    resource={CollectionsRus.RALOrder}
                    labelBtn="Создать RAL заказ"
                    confirmMsg="Вы уверены, что хотите создать RAL заказ?"
                />
            )}
        >
            <ReferenceInput
                label="Код"
                source="ralCodeId"
                reference={CollectionsRus.RAL}
                filterToQuery={searchText => ({ code: searchText, resetPagination: true, })}
                validate={[ required() ]}
            >
                <AutocompleteInput source='code' optionText="code" />
            </ReferenceInput>

            <CalcCostBtn />
        </SimpleForm>
    </Create>
);

export default RALCreate;
