import useScroll from '@hooks/useScroll';
import { useEffect, useState } from 'react';
import {
    Datagrid,
    List,
    Filter,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton,
    ListProps,
    FilterProps,
    SelectInput
} from 'react-admin';
import { styles } from '@constants/styles';

const ConsultationFilter = (props: Partial<FilterProps>) => {
    const scroll = useScroll();

    return (
        <div style={styles(scroll) as React.CSSProperties}>
            <Filter {...props}>
                <SelectInput label="Выбрать фильтр" alwaysOn source="isSeen" choices={[
                    { id: null, name: 'Все', },
                    { id: false, name: 'Не просмотреные', },
                    { id: true, name: 'Просмотреные', }
                ]} />
            </Filter>
        </div>
    );
};

const ConsultationList = (props: Partial<ListProps>) => (
    <List
        {...props}
        filters={<ConsultationFilter />}
    >
        <Datagrid>
            <TextField label='Имя' source="name" />

            <TextField label='E-mail' source="email" />

            <TextField label='Номер телефона' source="phone" />

            <ShowButton label="" />

            <EditButton label="" />

            <DeleteButton label="" />
        </Datagrid>
    </List>
);

export default ConsultationList;
