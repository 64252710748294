import {
    CreateParams,
    CreateResult,
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import Parse from 'parse';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import { RalOrderCommon } from '@interfaces/ral';
import { runCloud } from '@utils/run-cloud';
import { CloudFunctionName } from '@enum/parse-cloud';
import { filterContainers } from '@utils/ral-utils';
import ParseHelper from './ParseHelper';

const attributes: Array<keyof RalOrderCommon> = [
    'id',
    'ralCodeId',
    'cost',
    'needVolume',
    'needWeight',
    'orderIdentificator',
    'cost',
    'containersForDelivering',
    'containersForPreparing',
    'containersForHardners',
    'hardner',
    'totalVolume',
    'components',
    'orderName',
    'orderNumber'
];

@staticImplements<Pick<OverrideDataProvider<RalOrderCommon>, 'getList' | 'update' | 'create'>>()
export default class RalOrderClass {
    static async getList(
        _: string,
        { pagination, filter, sort, }: GetListParams
    ): Promise<GetListResult<RalOrderCommon>> {
        let ralQuery = new Parse.Query(Collections.RALOrder).descending('createdAt');

        const { code, } = filter ?? {};

        if (code) {
            ralQuery = ParseHelper.GetQueryForSearch('code', code, ralQuery);
        }

        ralQuery = ParseHelper.GetSortQuery(ralQuery, sort);

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            ralQuery,
            attributes
        );

        return result;
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<RalOrderCommon>> {
        const ralOrder = await ParseHelper.GetOneEntity(id, Collections.RALOrder, attributes);
        if (!ralOrder) {
            return ralOrder;
        }

        const { data: ralData, } = ralOrder;
        const {
            containersForDelivering,
            containersForPreparing,
            containersForHardners = {},
        } = ralData;

        return {
            data: {
                ...ralData,
                containersForDelivering: filterContainers(containersForDelivering),
                containersForPreparing: filterContainers(containersForPreparing),
                containersForHardners: filterContainers(containersForHardners),
            },
        };
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<RalOrderCommon>
    ): Promise<UpdateResult<RalOrderCommon>> {
        return ParseHelper.UpdateObject(data, true, Collections.RALOrder, String(id));
    }

    static async create(
        _: string,
        { data, }: CreateParams<RalOrderCommon>
    ): Promise<CreateResult<RalOrderCommon>> {
        const result = await runCloud(CloudFunctionName.CreateRalOrder, data);
        
        if (typeof result === 'string') {
            throw new Error(result);
        }

        return {
            data: { ...data, id: result.id, } as RalOrderCommon,
        };
    }
}
