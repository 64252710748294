import {
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import Parse from 'parse';

import { Category } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';
import { ArrayOfUploadedPhotos } from '@interfaces/file';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import { extractParamsFromUrl } from '@utils/workerWithUrl';
import ParseHelper from './ParseHelper';

const attributes: Array<keyof Category> = [
    'id_1c',
    'id',
    'name',
    'breadcrumbs',
    'photos',
    'slug',
    'translit',
    'metaDescription',
    'seoTitle',
    'seoHeader',
    'seoDescription',
    'isDeleted',
    'isPopular',
    'hasSubcategories',
    'countSubcategories',
    'isDangerous'
];

export type ParamsCreateCategory = Pick<
Category,
'name'
| 'metaDescription'
| 'seoTitle'
| 'seoHeader'
| 'seoDescription'
| 'id_1c'
| 'parentId'
> & {
    photos: ArrayOfUploadedPhotos,
};

export type OverridedCategory = Omit<Category, 'photos'> & { photos: ArrayOfUploadedPhotos };

@staticImplements<Pick<OverrideDataProvider<Category>, 'getList' | 'getOne' | 'update' | 'delete' | 'deleteMany'>>()
export default class CategoriesClass {
    static async getList(
        _: string,
        { filter, pagination, sort, }: GetListParams
    ): Promise<GetListResult<Category>> {
        const { parentCategory, name, resetPagination, } = filter ?? {};
        
        let categoriesQuery = new Parse.Query(Collections.Categories);

        if (name !== undefined) {
            categoriesQuery = ParseHelper.GetQueryForSearch<Category>('name', name, categoriesQuery);
        }

        if (!resetPagination) {
            categoriesQuery = categoriesQuery.containedIn<keyof Category>(
                'parentId',
                parentCategory ? [ parentCategory ]: [ null, '' ]
            );
            categoriesQuery = ParseHelper.GetSortQuery(categoriesQuery, sort);
        }

        if (resetPagination) {
            const result = await ParseHelper.GetAllSortedEntitiesForDropdown(categoriesQuery, attributes, 'slug');
            return result;
        }

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            categoriesQuery,
            attributes
        );

        return result;
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Category>> {
        return ParseHelper.GetOneEntity(id, Collections.Categories, attributes);
    }

    static async create(
        _: string,
        { data, }: CreateParams<ParamsCreateCategory>
    ): Promise<CreateResult<Category>> {
        const url = window.location.href;
        const parentCategory = extractParamsFromUrl(
            url.slice(url.indexOf('?')), 'refCategory'
        );

        data.parentId = parentCategory ?? '';

        return ParseHelper.CreateObject(
            data,
            Collections.Categories
        );
    }

    static async update(
        _: string,
        { id, data, previousData, }: UpdateParams<OverridedCategory>
    ): Promise<UpdateResult<Category>> {
        return await ParseHelper.UpdateDataForCategoryOrProduct<OverridedCategory, Category>(
            data,
            id,
            previousData,
            Collections.Categories
        );
    }

    static async delete(
        _: string,
        {
            id, previousData,
        }: DeleteParams
    ): Promise<DeleteResult<Category>> {
        return ParseHelper.DeleteOne<Category>(id, Collections.Categories, previousData);
    }

    static async deleteMany(
        _: string,
        { ids, }: DeleteManyParams
    ): Promise<DeleteManyResult> {
        return ParseHelper.DeleteMany<Category>(ids, Collections.Categories, attributes);
    }

    static async getMany(
        _: string,
        { ids, }: GetManyParams
    ): Promise<GetManyResult<Category>> {
        return ParseHelper.GetManyEntities(ids, Collections.Categories, attributes, 'slug');
    }
}
