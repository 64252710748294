import React, { FC } from 'react';
import { Edit, SimpleForm, TextInput, EditProps, SelectInput, required, FunctionField } from 'react-admin';
import { PvzProviderList } from './constants/pvzProvider';
import { listShipping } from './constants/shipping';
import { listStatus } from './constants/statusOrder';

const OrdersEdit: FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm mutationMode="pessimistic">
            <TextInput
                label="№"
                source="orderNumber"
                disabled
            />

            <SelectInput
                label="Статус заказа"
                source="status"
                choices={listStatus}
            />

            <TextInput
                label="Город"
                source="shippingInfo.city"
                validate={required()}
            />

            <TextInput
                label="Адрес"
                source="shippingInfo.address"
                validate={required()}
            />

            <TextInput
                label="Индекс"
                source="shippingInfo.postalCode"
            />

            <SelectInput
                label="Способ доставки"
                source="shippingInfo.method"
                choices={listShipping}
            />

            <SelectInput
                label="Поставщик"
                source="shippingInfo.pvzProvider"
                choices={PvzProviderList}
            />
        </SimpleForm>
    </Edit>
);

export default OrdersEdit;