import { ArrayOfUploadedPhotos, BlobObject, TmpFileData, TmpPhotoData } from '@interfaces/file';
import Parse from 'parse';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import { CloudFunctionName } from '@enum/parse-cloud';
import awaiter from './awaiter';
import { runCloud } from './run-cloud';
import { adaptFileUrl, exctractError } from './index';

export const uploadFile = async (
    uri: string,
    prefix: 'photo_' | 'manual_',
    ext: string = 'png',
    object?: Parse.Object,
    needSetToFileField?: boolean
) => {
    const fileName = `${prefix}${uuidv4().replace(/_/, '-')}`;

    const file = new Parse.File(
        fileName,
        { uri, }
    );
    await file.save();
    
    if (needSetToFileField && object) {
        object.set('file', file);
        await object.save();
    }
    await awaiter();
    return {
        url: adaptFileUrl(file._url),
        fileName: `${fileName}.${ext}`,
    };
};

export const handleResultOfUploading = async <T extends ArrayOfUploadedPhotos | Array<TmpFileData | TmpPhotoData | BlobObject>>(
    uploadResult: PromiseSettledResult<{ url: string, fileName: string }>[],
    files: T,
    pathToTitle: 'photo.title' | 'title',
    typeEntity: 'Photo' | 'File'
) => {
    const errors = [] as Array<{ description: string, error: string }>;

    const filesHaveLargeSize: string[] = [];

    const resultUploadedFiles = uploadResult.reduce((uploadedFiles, uploadPhotoResult, idx) => {
        if (uploadPhotoResult.status === 'fulfilled') {
            const {
                url,
                fileName,
            } = uploadPhotoResult.value;

            uploadedFiles.urls.push(url);
            uploadedFiles.names.push(fileName);
        } else {
            const { reason, } = uploadPhotoResult;

            const handledError = exctractError(reason);

            const title = get(files[idx], pathToTitle);

            if (handledError.includes('entity too large')) {
                filesHaveLargeSize.push(`${title} не был загружен из-за большого размера`);
            }

            errors.push({
                description: `${typeEntity} with name ${title} wasn\'t uploaded`,
                error: handledError,
            });
        }
        return uploadedFiles;
    }, { urls: [], names: [], } as { urls: string[], names: string[] });

    if (errors.length) {
        await runCloud(CloudFunctionName.AddErrorLog, { errors, });
    }

    const { urls, names, } = resultUploadedFiles;
    return { resultUploadedFiles: urls, resultUploadedFileNames: names, filesHaveLargeSize, };
};

export const uploadAnyFile = async (
    flle: TmpFileData,
    prefix: 'manual_'
) => {
    const uploadResult = await Promise.allSettled([ flle ].map(async (handledFile) => {
        const fileData = await uploadFile(
            handledFile.src,
            prefix
        );

        return fileData;
    }));

    const {
        resultUploadedFiles,
        filesHaveLargeSize,
    } = await handleResultOfUploading(uploadResult, [ flle ], 'title', 'File');

    const [ resultUploadedFile ] = resultUploadedFiles;
    const [ fileHaveLargeSize ] = filesHaveLargeSize;

    return {
        resultUploadedFile: resultUploadedFile ?? '',
        fileHaveLargeSize: fileHaveLargeSize ?? '',
    };
};

export const executeActionForFile = async <T extends Record<string, any>>(
    current: TmpFileData,
    old: TmpFileData | null,
    object: Parse.Object,
    field: keyof T
)  => {
    let action = 'left';
    switch (true) {
    case Boolean(current?.src && !old):
        action = 'put';
        break;
    case Boolean(!current?.src && old):
        action = 'delete';
        object.set(field as string, '');
        break;
    case Boolean(current?.src === old?.src):
        action = 'left';
        break;
    default:
        action = 'put';
        break;
    }

    if (action === 'put') {
        const {
            resultUploadedFile,
            fileHaveLargeSize,
        } = await uploadAnyFile(current, 'manual_');

        if (resultUploadedFile) {
            object.set(field as string, resultUploadedFile);
        } else {
            object.set(field as string, old?.src ?? '');
        }

        return fileHaveLargeSize;
    }

    return null;
};
