import {
    Create,
    SimpleForm,
    TextInput,
    CreateProps,
    ArrayInput,
    ImageInput,
    SimpleFormIterator,
    required,
    ImageField,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    number,
    minValue,
    maxValue,
    AutocompleteInput,
    FileInput,
    FileField
} from 'react-admin';

import { Collections, CollectionsRus } from '@enum/enum';
import { stylesForDropZone } from '@styles/index';

import RichTextInput  from 'ra-input-rich-text';
import useCustomNotify from '@hooks/useNotify';
import { errMsg, UniqueField } from '@utils/UniqueField';
import { useEffect } from 'react';

let uniqueSku: null | UniqueField = null;

const ProductCreate = (props: CreateProps) => {
    const styles = stylesForDropZone();

    const {
        onSuccess,
        onFailure,
    } = useCustomNotify(CollectionsRus.Products, 'edit', 'create');

    useEffect(() => {
        uniqueSku = new UniqueField(Collections.Products, 'sku');
    }, []);

    return (
        <Create 
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <SimpleForm mutationMode='pessimistic' className={styles.common}>
                <TextInput
                    label="Название"
                    source="name"
                    validate={required()}
                />

                <RichTextInput
                    source='description'
                    label="Описание"
                    fullWidth={false}
                />

                {/* <TextInput
                    label="ID 1C"
                    source="id_1c"
                    validate={required()}
                /> */}
                <TextInput
                    label="Sku"
                    source="sku"
                    validate={[
                        required(),
                        number(),
                        (value, { id, }) => uniqueSku?.IsValueUnique(value, id) ?? errMsg
                    ]}
                />

                <TextInput
                    label="Полезное"
                    source="useful"
                    options={{ multiline: true, }}
                />

                <TextInput
                    label="Рекомендации"
                    source="recommendation"
                    options={{ multiline: true, }}
                />

                <NumberInput
                    label="Цена" source="price" validate={[ required(), number(), minValue(1) ]}
                />

                <ReferenceInput
                    label="Бренд"
                    source="brandId"
                    reference={CollectionsRus.Brands}
                    allowEmpty
                    filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                >
                    <AutocompleteInput source='name' />
                </ReferenceInput>

                <NumberInput
                    label="Количество" source="totalAmount" validate={[ required(), number(), minValue(0) ]}
                />

                <NumberInput
                    label="Будет начислено бонусов %"
                    source="bonusPercent"
                    validate={[ required(), number(), minValue(0), maxValue(100) ]}
                />

                <BooleanInput
                    label="В наличии" source="inStock" validate={required()} defaultValue={false}
                />

                <BooleanInput
                    label="На распродаже" source="isOnSale" validate={required()} defaultValue={false}
                />

                <NumberInput
                    label="Ширина (см)" source="sizeX" validate={[ required(), number(), minValue(0.01) ]}
                />

                <NumberInput
                    label="Длина (см)" source="sizeY" validate={[ required(), number(), minValue(0.01) ]}
                />

                <NumberInput
                    label="Высота (см)" source="sizeZ" validate={[ required(), number(), minValue(0.01) ]}
                />

                <NumberInput
                    label="Вес (кг)" source="weight" step={0.001} validate={[ required(), number(), minValue(0.001) ]}
                />

                <NumberInput
                    label="Плотность" source="density" step={0.001}
                />

                <NumberInput
                    label="Вес единицы (кг)" source="singleWeight" step={0.001}
                />

                <NumberInput
                    label="Осталось (кг)" source="totalResidueWeight" step={0.001}
                />

                <TextInput
                    label="Meta описание"
                    source="metaDescription"
                    options={{ multiline: true, }}
                />

                <TextInput label="Seo название" source="seoTitle" />

                <TextInput label="Seo заголовок" source="seoHeader" />

                <TextInput
                    label="Seo описание"
                    source="seoDescription"
                    options={{ multiline: true, }}
                />

                <BooleanInput label="Популярный" source="isPopular" />

                <BooleanInput label="Опасный" source="isDangerous" />

                <BooleanInput
                    label="Не накладывать водяной знак"
                    source='resetUseWatermark'
                />

                <ArrayInput defaultValue={[]} source="photos">
                    <SimpleFormIterator>
                        <ImageInput
                            source="photo"
                            label="Изображения"
                            accept="image/*"
                            placeholder={<p>Перенесите изображения</p>}
                        >
                            <ImageField source="image" title="title" />
                        </ImageInput>
                    </SimpleFormIterator>
                </ArrayInput>

                <FileInput
                    source="manual"
                    label="Файл с инструкцией"
                    accept="application/pdf"
                >
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};

export default ProductCreate;
