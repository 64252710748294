import {
    CreateParams,
    CreateResult,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import Parse from 'parse';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import { LastTelegramMessage } from '@interfaces/telegram';
import ParseHelper from './ParseHelper';

const attributes: Array<keyof LastTelegramMessage> = [
    'id',
    'lastMessageLink'
];

@staticImplements<Pick<OverrideDataProvider<LastTelegramMessage>, 'getList' | 'getOne' | 'update' | 'create'>>()
export default class TelegramClass {
    static async getList(
        _: string
    ): Promise<GetListResult<LastTelegramMessage>> {
        const links = await new Parse.Query(Collections.Telegram).limit(1).find();

        return {
            data: links.map((tgLink) => ParseHelper.ExtractAttributes(tgLink, attributes)),
            total: 1,
        };
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<LastTelegramMessage>> {
        const telegramLink = await ParseHelper.GetOneEntity(id, Collections.Telegram, attributes);
        return telegramLink;
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<LastTelegramMessage>
    ): Promise<UpdateResult<LastTelegramMessage>> {
        return ParseHelper.UpdateObject(data, true, Collections.Telegram, String(id));
    }

    static async create(
        _: string,
        { data, }: CreateParams<LastTelegramMessage>
    ): Promise<CreateResult<LastTelegramMessage>> {
        const parseObject = new Parse.Object(Collections.Telegram);
        const { id, } = await parseObject.save(data, { useMasterKey: true, });

        return {
            data: {
                ...data,
                id,
            },
        };
    }
}
