import ExportBtn from '@components/common/ExportBtn';
import useDownload from '@hooks/useDownload';
import useScroll from '@hooks/useScroll';
import { useEffect, useRef, useState } from 'react';
import {
    Datagrid,
    List,
    TextField,
    ShowButton,
    ListProps,
    DateField,
    EditButton,
    FilterProps,
    Filter,
    TextInput,
    SelectInput,
    FunctionField
} from 'react-admin';
import { styles } from '@constants/styles';
import { MyTooltip } from './components/tooltip';
import { listStatus } from './constants/statusOrder';

const OrdersFilter = (props: Partial<FilterProps>) => {
    const scroll = useScroll();

    return (
        <div style={styles(scroll) as React.CSSProperties}>
            <Filter {...props}>
                <TextInput label="ФИО" source="snm" alwaysOn />

                <TextInput label="Номер телефона" source="phone" alwaysOn />

                <SelectInput label="Статус заказа" alwaysOn source="status" choices={listStatus} />
            </Filter>
        </div>
    );
};

const OrdersList = (props: Partial<ListProps>) => {
    const {
        IvisibleButton,
        onDownload,
        onGenerateDownloadLink,
        onSetFileName,
    } = useDownload();

    return (
        <List
            {...props}
            bulkActionButtons={false}
            filters={<OrdersFilter />}
            sort={{ field: 'orderNumber', order: 'DESC', }}
        >
            <Datagrid>
                <TextField label='№' source="orderNumber" />

                <TextField label='Имя' source="user.firstName" />

                <TextField label='Фамилия' source="user.lastName" />

                <FunctionField label="Статус" render={() =>
                    <div style={{ display:'flex', alignItems: 'center', }}>
                        <TextField label='Статус' source="status" />

                        <MyTooltip props={props} />
                    </div>
                }>
                    <TextField label='Статус' source="status" />
                </FunctionField>

                <DateField label="Дата заказа" source="orderDate" showTime={true} />

                <TextField label="Способ доставки" source="deliveryMethod" />

                <TextField label="Способ оплаты" source="paymentMethod" />

                <ExportBtn
                    type='orders-xml'
                    onGenerateDownloadLink={onGenerateDownloadLink}
                    onDownload={onDownload}
                    onSetFileName={onSetFileName}
                    ext="xml"
                />

                <ExportBtn
                    type='orders-pdf'
                    onGenerateDownloadLink={onGenerateDownloadLink}
                    onDownload={onDownload}
                    onSetFileName={onSetFileName}
                    ext="pdf"
                />

                <IvisibleButton />

                <ShowButton />

                <EditButton />
            </Datagrid>
        </List>
    );
};

export default OrdersList;