import { EMethodShipping } from '@enum/order';

type shipping = {
    id: EMethodShipping;
    name: string
};

export const listShipping: shipping[] = [
    { id: EMethodShipping.PKUP, name: 'Самовывоз', },
    // { id: EMethodShipping.TAXI, name: 'Яндекс.Такси', },
    { id: EMethodShipping.API_SHIP, name: 'API SHIP', }
];