import { useEffect, useState } from 'react';

const useScroll = () => {
    const [ scroll, setScroll ] = useState<boolean>(false);

    const checkScroll = () => {
        if(window.scrollY >= 100){
            setScroll(true);
        }else{
            setScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScroll);

        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    return scroll;
};

export default useScroll;
