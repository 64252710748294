import { FC } from 'react';
import { ListActions } from '@components/products/components/topToolBar';
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    ShowButton,
    TextField
} from 'react-admin';

const UsersList: FC<ListProps> = (props) => (
    <List
        {...props}
        actions={
            <ListActions
                btns={[ {
                    type: 'users-csv',
                    nameBtn: 'в CSV',
                }, {
                    type: 'users-xslt',
                    nameBtn: 'в XSLT',
                } ]}
            />
        }
    >
        <Datagrid>
            <TextField source="id" label="ID" />

            <TextField source='username' label="Имя пользователя" />

            <TextField source="firstName" label="Имя" />

            <TextField source="lastName" label="Фамилия" />

            <TextField source="email" label="Почта" />

            <TextField source="phone" label="Телефон" />

            <TextField source="bonusAmount" label="Количество бонусов" />

            <BooleanField source="isAllowCashOnDelivery" label="Разрешён Н/П" />

            <ShowButton />

            <EditButton />
        </Datagrid>
    </List>
);

export default UsersList;