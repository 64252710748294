import {
    CreateParams,
    CreateResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import { Compilation } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import Parse from 'parse';
import ParseHelper from './ParseHelper';

export type ParamsCreateCompilation = Omit<Compilation, 'preview'> &
    { preview: string,  }
;

const attributes: Array<keyof Compilation> = [
    'preview',
    'title',
    'description',
    'link',
    'id'
];
@staticImplements<Pick<OverrideDataProvider<Compilation>, 'create' | 'getList' | 'getOne' | 'update'>>()
export default class CompilationClass {
    static async getList(
        _: string,
        { filter, pagination, sort, }: GetListParams
    ): Promise<GetListResult<Compilation>>{
        const { title, } = filter ?? {};
        let compilationQuery = new Parse.Query(Collections.Compilation);

        compilationQuery = ParseHelper.GetSortQuery(compilationQuery, sort);

        if (title !== undefined) {
            compilationQuery = ParseHelper.GetQueryForSearch<Compilation>('title', title, compilationQuery);
        }

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            compilationQuery,
            attributes
        );

        return result;
    }

    static async create(
        _: string,
        { data, }: CreateParams<Compilation>
    ): Promise<CreateResult<Compilation> & { resultPhotosHaveLargeSize: string[] }> {
        const parseObject = new Parse.Object(Collections.Compilation);

        const { preview, ...savedData } = data;

        const issuesWithLargeSize: string[] = await ParseHelper.UploadImages(
            data,
            parseObject
        );

        await parseObject.save(savedData);

        return {
            data: { ...data, id: parseObject.id, } as any as Compilation,
            resultPhotosHaveLargeSize: issuesWithLargeSize,
        };
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<ParamsCreateCompilation>
    ): Promise<UpdateResult<Compilation> & { resultPhotosHaveLargeSize: string[] }> {
        const { preview, ...savedData } = data;

        const uploadedImages: Parameters<typeof ParseHelper.UploadImages>[2] = [];

        if (!preview.includes(process.env.REACT_APP_STORAGE_URL ?? '')) {
            uploadedImages.push('preview');
        }

        const savedObject = await ParseHelper.GetObject(id, Collections.Compilation);
        
        const issuesWithLargeSize: string[] = await ParseHelper.UploadImages(
            data,
            savedObject,
            uploadedImages
        );
            
        await ParseHelper.UpdateObject(savedData, true, undefined, undefined, savedObject);

        return {
            data: data as any as Compilation,
            resultPhotosHaveLargeSize: issuesWithLargeSize,
        };
    }

    static async delete(
        _: string,
        {
            id, previousData,
        }: DeleteParams
    ): Promise<DeleteResult<Compilation>> {
        return ParseHelper.DeleteOne<Compilation>(id, Collections.Compilation, previousData);
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Compilation>> {
        return ParseHelper.GetOneEntity(id, Collections.Compilation, attributes);
    }
}
