
import { OverrideDataProvider } from '@interfaces/global';
import OrdersClass from '@parse_entity_utils/order';

const OrderDataProvider: Pick<OverrideDataProvider, 'getList' | 'update' | 'getOne'
> = {
    getList: OrdersClass.getList,
    getOne: OrdersClass.getOne,
    update: OrdersClass.update,
};

export default OrderDataProvider;
