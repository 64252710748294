import Parse from 'parse';
import { OverrideDataProvider } from '@interfaces/global';
import staticImplements from '@utils/staticImplements';
import { GetListResult, GetOneParams, GetOneResult } from 'react-admin';
import { CloudFunctionName } from '@enum/parse-cloud';
import { runCloud } from '@utils/run-cloud';

@staticImplements<Pick<OverrideDataProvider<any>, 'getOne' | 'getList'>>()
export default class LogsClass {
    private static async getLogs(
        type: 'all' | 'error'
    ) {
        try {
            let logsResult = await runCloud(
                type === 'all'
                    ? CloudFunctionName.AllLogs
                    : CloudFunctionName.ErrLogs
            );

            if (logsResult.length === 'user hasn\'t enouth root'.length) {
                return '[]';
            }

            logsResult = logsResult.replaceAll('\n', ',');
            logsResult = logsResult.slice(0, -1);
            
            return `[${logsResult ?? ''}]`;
        } catch (error) {
            console.log('error', error);
            return '[]';
        }
    }

    static async getList(
        _: string
    ): Promise<GetListResult<any>> {
        return {
            data: [ {
                id: 'all',
                name: 'Общие',
                array: '[]',
            }, {
                id: 'error',
                name: 'Ошибки',
                array: '[]',
            } ],
            total: 2,
        };
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<any>> {
        const logs = await LogsClass.getLogs(id as 'error' | 'all');

        return {
            data: { id, array: logs, },
        };
    }
}