import React, { FC } from 'react';
import { Create, CreateProps, SimpleForm, TextInput, required } from 'react-admin';

export const BlogGroupsCreate: FC<CreateProps> = (props) => (
    <Create
        {...props}
    >
        <SimpleForm>
            <TextInput
                source='name'
                label="Название"
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export default BlogGroupsCreate;