import Parse from 'parse';

import { CloudFunctionName } from '@enum/parse-cloud';

export const runCloud = async(
    funcName: CloudFunctionName,
    params?: any
) => {
    try {
        const options: Parse.Cloud.RunOptions = {
            sessionToken: localStorage.getItem('parseToken') ?? undefined,
        };

        const result = await Parse.Cloud.run(funcName, params, options);

        return result;
    } catch (error) {
        console.log(`Error in runCloud - ${funcName}`, error);

        return null;
    }
};
