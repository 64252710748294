import React, { FC } from 'react';
import { BooleanField, ImageField, NumberField, RichTextField, Show, ShowProps, Tab, TabbedShowLayout, TextField } from 'react-admin';

const BlogsShow: FC<ShowProps> = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Основное">
                <TextField
                    label="ID"
                    source="id"
                />

                <TextField
                    source="name"
                    label="Название"
                />

                <TextField
                    source='slug'
                    label="Slug"
                />

                <TextField
                    source="categoryName"
                    label="Название категории"
                />

                <BooleanField
                    source="isDeleted"
                    label="Удален"
                />
            </Tab>

            <Tab label="Значения">
                <TextField
                    source='timeToRead'
                    label="Время для чтения"
                />

                <NumberField
                    source="views"
                    label="Просмотры"
                />
            </Tab>

            <Tab label="Фото и текст">
                <RichTextField
                    source="text"
                    label="Текст"
                />

                <ImageField
                    source="cover.image"
                    label="Главное фото"
                />

                <ImageField
                    source="preview"
                    label="Превью фото"
                />
            </Tab>

            <Tab label="SEO">
                <TextField
                    label="SEO Заголовок"
                    source='seoTitle'
                />

                <TextField
                    label="SEO Описание"
                    source="seoDescription"
                />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default BlogsShow;