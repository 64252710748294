import { ArrayOfUploadedPhotos, BlobObject, PhotoObject, TmpPhotoData } from '@interfaces/file';
import { get } from 'lodash';
import Parse from 'parse';
import { handleResultOfUploading, uploadFile } from './fileUtils';

const extractExtenstion = (fillNameFile: string) => {
    const clearName = fillNameFile?.split('.');
    const ext = clearName.pop();
    return ext;
};

export const uploadPhoto = async (
    object: Parse.Object,
    photos: ArrayOfUploadedPhotos | Array<BlobObject> | Array<TmpPhotoData>,
    pathToBlobUrl: 'blobUrl' | 'photo.image' | 'image' = 'photo.image',
    pathToTitle: 'photo.title' | 'title' = 'photo.title',
    needSetToFileField: boolean = true
) => {
    const uploadResult = await Promise.allSettled(photos.map(async (photo) => {
        const url = await uploadFile(
            get(photo, pathToBlobUrl),
            'photo_',
            extractExtenstion(get(photo, pathToTitle)),
            object,
            needSetToFileField
        );

        return url;
    }));

    const {
        resultUploadedFiles,
        resultUploadedFileNames,
        filesHaveLargeSize,
    } = await handleResultOfUploading(uploadResult, photos, pathToTitle, 'Photo');

    return {
        resultUploadedPhotos: resultUploadedFiles,
        resultPhotosHaveLargeSize: filesHaveLargeSize,
        resultUploadedPhotoNames: resultUploadedFileNames,
    };
};

export const definePhotosActions = (
    prevPhotos: PhotoObject[],
    currPhotos: ArrayOfUploadedPhotos
) => {
    const deletedPhotos: string[] = [];
    const addedPhotos: ArrayOfUploadedPhotos = [ ...currPhotos ];

    const findPrevPhotoAtCurrentPhotos = ({ image: imageUrl, }: PhotoObject) => (
        addedPhotos.findIndex(({ photo: { rawFile, }, }) => (
            typeof rawFile === 'string' && rawFile === imageUrl
        )));

    prevPhotos.forEach((photo, idx) => {
        const foundIdxPhoto = findPrevPhotoAtCurrentPhotos(photo);

        if (foundIdxPhoto === -1) {
            deletedPhotos.push(photo.image);
        } else {
            addedPhotos.splice(foundIdxPhoto, 1);
        }
    });

    return {
        deletedPhotos,
        addedPhotos,
    };
};
