import CategoriesClass from '@parse_entity_utils/categories';

import { Category } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

const CategoryDataProvider: Pick<OverrideDataProvider<
Category>, 'getList' | 'getOne' | 'create' | 'update' | 'delete' | 'deleteMany' | 'getMany'
> = {
    getList: CategoriesClass.getList,
    getOne: CategoriesClass.getOne,
    create: CategoriesClass.create,
    update: CategoriesClass.update,
    delete: CategoriesClass.delete,
    deleteMany: CategoriesClass.deleteMany,
    getMany: CategoriesClass.getMany,
};

export default CategoryDataProvider;
