import { OrderStatus } from '@enum/order';
import { SelectInputItem } from '@interfaces/global';

export const listStatus: SelectInputItem<OrderStatus>[] = [
    { id: OrderStatus.CRD , name: OrderStatus.CRD, },
    { id: OrderStatus.WFP, name: OrderStatus.WFP, },
    { id: OrderStatus.PFS, name: OrderStatus.PFS, },
    { id: OrderStatus.OIP, name: OrderStatus.OIP, },
    { id: OrderStatus.SIP, name: OrderStatus.SIP, },
    { id: OrderStatus.CLR, name: OrderStatus.CLR, },
    { id: OrderStatus.PFD, name: OrderStatus.PFD, },
    { id: OrderStatus.CLD, name: OrderStatus.CLD, },
    { id: OrderStatus.DEL, name: OrderStatus.DEL, },
    { id: OrderStatus.OW, name: OrderStatus.OW, },
    { id: OrderStatus.RET, name: OrderStatus.RET, },
    { id: OrderStatus.Err, name: OrderStatus.Err, },
    { id: OrderStatus.RR, name: OrderStatus.RR, },
    { id: OrderStatus.OSP, name: OrderStatus.OSP, }
];
