import React, { FC } from 'react';
import { BooleanInput, Edit, EditProps, required, SimpleForm, TextInput } from 'react-admin';

const BlogGroupsEdit: FC<EditProps> = (props) => (
    <Edit
        {...props}
    >
        <SimpleForm>
            <TextInput
                source='name'
                label="Название"
                validate={required()}
            />

            <TextInput
                source='slug'
                label="Slug"
                disabled
            />

            <BooleanInput
                source='isDeleted'
                label="Удален"
                defaultValue={false}
            />
        </SimpleForm>
    </Edit>
);

export default BlogGroupsEdit;