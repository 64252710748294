import { TableHead, TableRow, TableCell } from '@mui/material';
import React, { FC } from 'react';

export const ComponentsHardnerHeader: FC<{}> = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Название</TableCell>
                <TableCell align="right">sku</TableCell>
                <TableCell align="right">Стоимость&nbsp;(руб)</TableCell>
                <TableCell align="right">Требуемый вес&nbsp;(кг)</TableCell>
                <TableCell align="right">Вес в наличии&nbsp;(кг)</TableCell>
            </TableRow>
        </TableHead>
    );
};
