import {
    EditProps,
    Edit,
    SimpleForm,
    TextInput,
    required,
    BooleanInput
} from 'react-admin';

const ConsultationEdit = (props: EditProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Имя" source="name" validate={required()} />

            <TextInput label="Номер телефона" source="phone" validate={required()} />

            <TextInput label="Почта" source="email" validate={required()} />

            <TextInput
                label="Вопрос"
                source="question"
                options={{ multiline: true, }}
            />

            <BooleanInput
                label="Просмотрено"
                source="isSeen"
                defaultValue={false}
            />
        </SimpleForm>
    </Edit>
);

export default ConsultationEdit;
