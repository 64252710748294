import { CollectionsRus } from '@enum/enum';
import { CircularProgress } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import {
    Button,
    Confirm,
    FormDataConsumer,
    useCreate,
    useNotify,
    useRedirect
} from 'react-admin';

type CustomConfirmCreate = {
    resource: CollectionsRus,
    labelBtn: string,
    confirmMsg: string,
    errorMsg?: string,
    successMsg?: string,
};

export const CreateEntityWithConfirm: FC<CustomConfirmCreate> = ({
    labelBtn,
    confirmMsg,
    resource,
    errorMsg = 'Произошла ошибка',
    successMsg = 'Успешно',
}) => {
    const [ open, setOpen ] = useState(false);
    const initFlag = useRef<boolean>(false);

    const [ create, { loading: isLoading, loaded, data, } ] = useCreate();

    const redirect = useRedirect();
    const notify = useNotify();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = async (formData: any) => {
        try {
            await create({
                resource,
                payload: { data: formData, },
            });
            
            setOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!isLoading) {
            if (!initFlag.current) {
                initFlag.current = true;
                return;
            }

            if (!data) {
                notify(
                    errorMsg,
                    'error'
                );
            } else {
                const { id, } = data;
                notify(
                    successMsg,
                    'success'
                );

                setTimeout(() => {
                    redirect('show', `/${CollectionsRus.RALOrder}`, id, data);
                }, 2000);
            }
        }
    }, [ isLoading ]);

    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, }) => (
                <>
                    <Button
                        label={labelBtn}
                        onClick={handleClick}
                        variant="contained"
                        disabled={formData.disableConfirm || formData.isLoading}
                    />
    
                    <Confirm
                        isOpen={open}
                        loading={isLoading}
                        title={labelBtn}
                        content={confirmMsg}
                        onConfirm={() => handleConfirm(formData)}
                        onClose={handleDialogClose}
                    />
                
                </>
            )}
        </FormDataConsumer>
    );
};