import {
    CreateParams,
    CreateResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import { BlogGroups, BlogGroups as BlogGroupsTypes } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import Parse from 'parse';
import ParseHelper from './ParseHelper';

export type ParamsCreateCategory = BlogGroupsTypes;

const attributes: Array<keyof BlogGroupsTypes> = [
    'name',
    'slug',
    'id',
    'isDeleted'
];
@staticImplements<Pick<OverrideDataProvider<BlogGroupsTypes>, 'create' | 'getList' | 'getOne' | 'update'>>()
export default class BlogGroupsClass {
    static async getList(
        _: string,
        { filter, pagination, sort, }: GetListParams
    ): Promise<GetListResult<BlogGroupsTypes>>{
        const { name, resetPagination, } = filter ?? {};
        let blogsQuery = new Parse.Query(Collections.BlogGroups);

        if (name !== undefined) {
            blogsQuery = ParseHelper.GetQueryForSearch<BlogGroupsTypes>('name', name, blogsQuery);
        }

        if (!resetPagination) {
            blogsQuery = ParseHelper.GetSortQuery(blogsQuery, sort);
        }

        if (resetPagination) {
            const result = await ParseHelper.GetAllSortedEntitiesForDropdown(blogsQuery, attributes, 'slug');
            return result;
        }

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            blogsQuery,
            attributes
        );

        return result;
    }

    static async create(
        _: string,
        { data, }: CreateParams<BlogGroupsTypes>
    ): Promise<CreateResult<BlogGroupsTypes>> {
        const parseObject = new Parse.Object(Collections.BlogGroups);

        await parseObject.save(data);

        return {
            data: { ...data, id: parseObject.id, } as BlogGroupsTypes,
        };
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<BlogGroupsTypes>
    ): Promise<UpdateResult<BlogGroupsTypes>> {
        return await ParseHelper.UpdateObject(data, true, Collections.BlogGroups, String(id));
    }

    static async delete(
        _: string,
        {
            id, previousData,
        }: DeleteParams
    ): Promise<DeleteResult<BlogGroups>> {
        return ParseHelper.DeleteOne<BlogGroups>(id, Collections.BlogGroups, previousData);
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<BlogGroupsTypes>> {
        return ParseHelper.GetOneEntity(id, Collections.BlogGroups, attributes);
    }

    static async getMany(
        _: string,
        { ids, }: GetManyParams
    ): Promise<GetManyResult<BlogGroups>> {
        return ParseHelper.GetManyEntities(ids, Collections.BlogGroups, attributes, 'slug');
    }
}
