import {
    CreateParams,
    CreateResult,
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import { Blog } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import Parse from 'parse';
import { TmpPhotoData } from '@interfaces/file';
import ParseHelper from './ParseHelper';

export type ParamsCreateBlog = Omit<Blog, 'preview' | 'cover'> &
    { preview: string, cover: TmpPhotoData }
;

const attributes: Array<keyof Blog> = [
    'id',
    'name',
    'slug',
    'views',
    'blogGroupSlug',
    'categorySlug',
    'categoryName',
    'cover',
    'createdAt',
    'name',
    'seoDescription',
    'seoTitle',
    'text',
    'themeIcon',
    'timeToRead',
    'views',
    'preview',
    'isDeleted'
];

@staticImplements<Pick<OverrideDataProvider<Blog>, 'create' | 'update' | 'getList' | 'getOne'>>()
export default class BlogClass {
    static async getList(
        _: string,
        { filter, pagination, sort, }: GetListParams
    ): Promise<GetListResult<Blog>>{
        const { name, resetPagination, } = filter ?? {};
        let blogsQuery = new Parse.Query(Collections.Blogs);

        if (name !== undefined) {
            blogsQuery = ParseHelper.GetQueryForSearch<Blog>('name', name, blogsQuery);
        }

        if (!resetPagination) {
            blogsQuery = ParseHelper.GetSortQuery(blogsQuery, sort);
        }

        if (resetPagination) {
            const result = await ParseHelper.GetAllSortedEntitiesForDropdown(blogsQuery, attributes);
            return result;
        }

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            blogsQuery,
            attributes
        );

        return result;
    }

    static async create(
        _: string,
        { data, }: CreateParams<ParamsCreateBlog>
    ): Promise<CreateResult<Blog> & { resultPhotosHaveLargeSize: string[] }> {
        const parseObject = new Parse.Object(Collections.Blogs);

        const { cover, preview, ...savedData } = data;

        const issuesWithLargeSize: string[] = await ParseHelper.UploadImages(
            data,
            parseObject
        );

        await parseObject.save(savedData);

        return {
            data: { ...data, id: parseObject.id, } as any as Blog,
            resultPhotosHaveLargeSize: issuesWithLargeSize,
        };
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<ParamsCreateBlog>
    ): Promise<UpdateResult<Blog> & { resultPhotosHaveLargeSize: string[] }> {
        const { cover, preview, ...savedData } = data;

        const uploadedImages: Parameters<typeof ParseHelper.UploadImages>[2] = [];

        [ cover.image, preview ].forEach((image, idx) => {
            if (!image.includes(process.env.REACT_APP_STORAGE_URL ?? '')) {
                switch (idx) {
                case 0:
                    uploadedImages.push('cover');
                    break;
                case 1:
                    uploadedImages.push('preview');
                    break;
                default:
                    break;
                }
            }
        });

        const savedObject = await ParseHelper.GetObject(id, Collections.Blogs);
        
        const issuesWithLargeSize: string[] = await ParseHelper.UploadImages(
            data,
            savedObject,
            uploadedImages
        );
            
        await ParseHelper.UpdateObject(savedData, true, undefined, undefined, savedObject);

        return {
            data: data as any as Blog,
            resultPhotosHaveLargeSize: issuesWithLargeSize,
        };
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Blog>> {
        return ParseHelper.GetOneEntity(id, Collections.Blogs, attributes);
    }
}
