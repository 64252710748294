export const getImageURL = (item: any): string | undefined => {
    if (item && item.hasOwnProperty('imagename') && item.imagename !== null) {
        return `${process.env.REACT_APP_IMAGE_URL || ''}${item.imagename}`;
    }
    return undefined;
};

export const convertFileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
};