import {
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import { Consultation } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';
import Parse from 'parse';

import getPaginationData from '@utils/pagination';
import staticImplements from '@utils/staticImplements';

import { Collections } from '@enum/enum';

import ParseHelper from './ParseHelper';

const attributes: Array<keyof Consultation> = [ 'id', 'email', 'isSeen', 'name', 'phone', 'question' ];
@staticImplements<Pick<OverrideDataProvider<Consultation>, 'update'>>()
export default class ConsultationClass {
    static async getList(
        _: string,
        { filter: { isSeen, }, pagination, }: GetListParams
    ): Promise<GetListResult<Consultation>> {
        let consultationsQuery = new Parse.Query(Collections.Consultation);

        if (isSeen !== undefined) {
            consultationsQuery = consultationsQuery.equalTo<keyof Consultation>('isSeen', isSeen);
        }

        const consultations = (await consultationsQuery.findAll())
            .map((consultation) => (
                ParseHelper.ExtractAttributes<Consultation, keyof Consultation>(
                    consultation, attributes
                )
            ));

        return getPaginationData<Consultation>(pagination, consultations);
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Consultation>> {
        return ParseHelper.GetOneEntity(id, Collections.Consultation, attributes);
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<Consultation>
    ): Promise<UpdateResult<Consultation>> {
        return await ParseHelper.UpdateObject(data, true, Collections.Consultation, String(id));
    }

    static async delete(
        _: string,
        {
            previousData,
        }: DeleteParams
    ): Promise<DeleteResult<Consultation>> {
        if (previousData?.id) {
            const consultation = await ParseHelper.GetObject(previousData.id, Collections.Consultation);
            await consultation.destroy();
            return {
                data: previousData as Consultation,
            };
        }
        return {
            data: {} as Consultation,
        };
    }

    static async deleteMany(
        _: string,
        { ids, }: DeleteManyParams
    ): Promise<DeleteManyResult> {
        const oldConsultationData: Consultation[] = [];

        const consultations = await (new Parse.Query(Collections.Consultation)
            .containedIn('objectId', ids)
            .findAll());

        consultations.forEach((consultation) => {
            if (consultation) {
                oldConsultationData.push(
                    ParseHelper.ExtractAttributes<Consultation, keyof Consultation>(
                        consultation, attributes
                    )
                );
            }
        });

        await Parse.Object.destroyAll(consultations);

        return {
            data: ids,
        };
    }
}
