import { RalComponents } from '@interfaces/ral';

export const validator = (formState: any) => {
    const error: any = {};

    const {
        components = [],
        // koefWeightIn10L = {},
    } = formState ?? {};
    // const idxErrorComponents = (components as RalComponents[])
    //     .reduce((result, component: any, idx) => {
    //         if (Number((koefWeightIn10L * (component?.needWeightFor10KG ?? 0) / 10).toFixed(3)) < 0.001) {
    //             result.push(idx + 1);
    //         }
    //         return result;
    //     }, [] as number[]);

    // if (idxErrorComponents.length) {
    //     error.koefWeightIn10L = [
    //         'Пожалуйста, проверьте значения у компонентов:  Вес (кг) для получения 10 литров.',
    //         `Должно быть выше 0.001кг.\nИндексы компонентов: ${idxErrorComponents.join(', ')}`
    //     ].join('\n');
    // }

    if (components.length) {
        const productIds = (components as any[]).reduce((result, el) => {
            if (el?.productId) {
                result.push(el.productId);
            }

            return result;
        }, [] as string[]) as string[];

        const isExistDuplicates = productIds
            .some((productId, idx, array) => (
                idx !== array.lastIndexOf(productId)
            ));
        if (isExistDuplicates) {
            error.category = 'Пожалуйста, проверьте список компонентов. Требуется убрать дубликаты';
        }
    }
    return error;
};
