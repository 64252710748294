import { FC } from 'react';
import { Show, ShowProps, SimpleShowLayout } from 'react-admin';
import { JsonField } from 'react-admin-json-view';

export const ShowLogs: FC<ShowProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <JsonField
                source="array"
                addLabel={true}
                jsonString={true} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: false,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </SimpleShowLayout>
    </Show>
);
;