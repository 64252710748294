import {
    Datagrid,
    List,
    Filter,
    TextField,
    TextInput,
    EditButton,
    DeleteButton,
    ListProps,
    FilterProps,
    BooleanField,
    ShowButton,
    FunctionField,
    ImageField
} from 'react-admin';

// @ts-ignore
import { History } from 'history';

import { ProceedToSubcollection } from '@components/common/ProceedToSubcollection';
import { extractFilterData } from '@utils/workerWithUrl';
import { CollectionsRus } from '@enum/enum';
import { styles } from '@constants/styles';
import useScroll from '@hooks/useScroll';
import { stylesForImage } from '@styles/index';
import AddSubcategoryIcon from './addSubcategoryIcon';
import ActionCategoryBtn from './actionCategoryBtn';

const CategoriesFilter = (props: Partial<FilterProps>) => {
    const scroll = useScroll();

    return (
        <div style={styles(scroll) as React.CSSProperties}>
            <Filter {...props}>
                <TextInput label="Поиск" source="name" alwaysOn />
            </Filter>
        </div>
    );
};

const getNextProceedComponent = (
    props: Partial<ListProps>,
    direction: 'back' | 'next',
    canDoNextStep: boolean
) => (
    <ProceedToSubcollection
        history={(props as { history: History }).history}
        direction={direction}
        prevField="parentId"
        nextField="id"
        nameFilteredField="parentCategory"
        nameReturnedField="backCategoryId"
        nextCollection={CollectionsRus.Products}
        canDoNextStep={canDoNextStep}
    />
);

const CategoriesList = (props: Partial<ListProps>) => {
    const isRoot = !extractFilterData((props as any).history.location.search)?.parentCategory;
    const styles = stylesForImage();

    return (
        <List
            {...props}
            aside={
                <>
                    {!isRoot && getNextProceedComponent(props, 'back', false)}
                </>
            }
            filters={<CategoriesFilter />}
        >
            <Datagrid>
                <FunctionField label='Изображение' render={({ photos, preview, }: any) => {
                    if (photos[0]?.photo?.image) {
                        return <ImageField className={styles.image} label='Изображение' source="previewImg" />;
                    }
                    return <ImageField className={styles.image} label='Изображение' emptyText='Пусто' source="placeholder" />;
                }} />

                <TextField label='Имя категории' source="name" />

                <BooleanField label='Есть подкатегории' source="hasSubcategories" />

                <BooleanField label="Удалена" source="isDeleted" />

                <BooleanField label="Популярная" source="isPopular" />

                <ActionCategoryBtn Icon={ShowButton}/>

                <ActionCategoryBtn Icon={EditButton}/>

                <DeleteButton label="" />

                <FunctionField render={(record: any) => (
                    getNextProceedComponent(props, 'next', record.hasSubcategories)
                )} />

                <ActionCategoryBtn
                    needHideIcon={{
                        nameProps: 'hasSubcategories',
                        pozitive: false,
                    }}
                    Icon={AddSubcategoryIcon}
                />
            </Datagrid>
        </List>
    );
};

export default CategoriesList;
