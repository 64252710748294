import { useRef, useState } from 'react';

const useDownload = () => {
    const [ fileDownloadUrl, setFileDownloadUrl ] = useState<string>('');
    const [ fileName, setFileName ] = useState<string>('');

    const downloadRef = useRef() as any;

    const onGenerateDownloadLink = (link: string) => setFileDownloadUrl(link);

    const onSetFileName = (name: string) => setFileName(name);

    const onDownload = () => {
        const btn = downloadRef?.current;

        if (btn) {
            downloadRef.current.click();
        }
    };

    const IvisibleButton = () => (
        <a
            ref={downloadRef}
            onClick={(e) => {
                e.stopPropagation();
            }}
            style={{ display: 'none', }}
            download={fileName}
            href={fileDownloadUrl}
        >download it</a>
    );

    return {
        onGenerateDownloadLink,
        onSetFileName,
        onDownload,
        IvisibleButton,
    };
};

export default useDownload;
