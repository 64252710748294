import React, { FC } from 'react';
import { Create, CreateProps, SimpleForm, TextInput, required, NumberInput } from 'react-admin';

const UsersCreate: FC<CreateProps> = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source='username' label="Имя пользователя" validate={required()} />

            <TextInput source='firstName' label="Имя" validate={required()} />

            <TextInput source='lastName' label="Фамилия" validate={required()} />

            <TextInput source='middleName' label="Отчество" validate={required()} />

            <TextInput source='email' label="Почта" validate={required()} />

            <TextInput source='phone' label="Телефон" validate={required()} />

            <TextInput source='password' label="Пароль" validate={required()} />

            <NumberInput source='bonusAmount' label='Количество бонусов' validate={required()} />

            <NumberInput source='orderNumber' label='Количество заказов' defaultValue={0} disabled />
        </SimpleForm>
    </Create>
);

export default UsersCreate;