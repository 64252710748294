import {
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';
import Parse from 'parse';

import { Brand } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import ParseHelper from './ParseHelper';

const attributes: Array<keyof Brand> = [ 'id', 'name', 'slug', 'translit', 'isDeleted', 'id_1c' ];

@staticImplements<Pick<OverrideDataProvider<Brand>, 'update'> | 'getList' | 'getOne' | 'create' | 'delete' | 'deleteMany' | 'getMany'>()
export default class BrandClass {
    static async getList(
        _: string,
        { filter, pagination, sort, }: GetListParams
    ): Promise<GetListResult<Brand>> {
        const { name, resetPagination, } = filter ?? {};
        let brandsQuery = new Parse.Query(Collections.Brands);

        if (name !== undefined) {
            brandsQuery = ParseHelper.GetQueryForSearch<Brand>('name', name, brandsQuery);
        }

        if (!resetPagination) {
            brandsQuery = ParseHelper.GetSortQuery(brandsQuery, sort);
        }

        if (resetPagination) {
            const result = await ParseHelper.GetAllSortedEntitiesForDropdown(brandsQuery, attributes);
            return result;
        }

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            brandsQuery,
            attributes
        );

        return result;
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Brand>> {
        return ParseHelper.GetOneEntity(id, Collections.Brands, attributes);
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<Brand>
    ): Promise<UpdateResult<Brand>> {
        return await ParseHelper.UpdateObject(data, true, Collections.Brands, String(id));
    }

    static async delete(
        _: string,
        {
            id, previousData,
        }: DeleteParams
    ): Promise<DeleteResult<Brand>> {
        return ParseHelper.DeleteOne<Brand>(id, Collections.Brands, previousData);
    }

    static async deleteMany(
        _: string,
        { ids, }: DeleteManyParams
    ): Promise<DeleteManyResult> {
        return ParseHelper.DeleteMany<Brand>(ids, Collections.Brands, attributes);
    }

    static async create(
        _: string,
        { data, }: CreateParams<Pick<Brand, 'name'>>
    ): Promise<CreateResult<Brand>> {
        const createdBrand = new Parse.Object(Collections.Brands);
        await createdBrand.save(data);

        return {
            data: { id: createdBrand.id, ...data, } as Brand,
        };
    }

    static async getMany(
        _: string,
        { ids, }: GetManyParams
    ): Promise<GetManyResult<Brand>> {
        return ParseHelper.GetManyEntities(ids, Collections.Brands, attributes);
    }
}
