import { AsYouType } from 'libphonenumber-js';

const formatPhoneNumber = (text: string) => {
    let phone = text.replace('\s+', '');
    const firstSymbol = phone[0];
    if (firstSymbol === '8') {
        const phoneArr = phone.split('');
        phoneArr.splice(0, 1, '+7');
        phone = phoneArr.join('');
    }

    const number = new AsYouType('RU').input(phone);
    return number;
};

export default formatPhoneNumber;
