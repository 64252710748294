const prepareRegExpForSearch = (name: string) => {
    const adaptedName = name
        .replace(/\s+/g, ' ').replace(/(^\s+|\s+$)/g, '')
        // eslint-disable-next-line no-useless-escape
        .replace(/[+\\*.?^()|/\[\]\$]/g, (match) => `\\${match}`);

    if (name.split(/\s+/).length > 1) {
        return `.*(${adaptedName.split(/\s+/).join(').*(')}).*`;
    }
    return adaptedName.replace(/\s+/g, '\\s+');
};

export default prepareRegExpForSearch;
