import {
    Show,
    SimpleShowLayout,
    TextField,
    ShowProps,
    RichTextField,
    BooleanField
} from 'react-admin';

const ConsultationShow = (props: Partial<ShowProps>) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Имя" source="name" />

            <TextField label="Номер телефона" source="phone" />

            <TextField label="E-mail" source="email" />

            <RichTextField label="Вопрос" source="question" />

            <BooleanField label="Консультация просмотрена" source="isSeen" />
        </SimpleShowLayout>
    </Show>
);

export default ConsultationShow;
