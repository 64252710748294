import React, { FC } from 'react';
import { Typography, Button, Modal, Box, CircularProgress } from '@mui/material';

interface ModalOrderProps {
    open: boolean;
    loading: boolean;
    handleCloseModal:  () => void;
    createOrderApiShip: boolean;
    changeOderApiShip: () => void;
}

export const ModalOrder: FC<ModalOrderProps> = ({
    open,
    loading,
    handleCloseModal,
    createOrderApiShip,
    changeOderApiShip,
}) => (
    <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={styles.modal}>
            {loading && (
                <div style={styles.containerLoader}>
                    <CircularProgress />

                    <p>{`Происходит ${!createOrderApiShip ? 'создание заказа в' : 'удаление заказа из'} API SHIP`}</p>
                </div>
            )}

            {!loading && (
                <>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Подтверждение
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2, }}>
                        {createOrderApiShip ?
                            'Вы точно хотите удалить товар из Api Ship?'
                            : 'Вы точно хотите добавить товар в Api Ship?'
                        }
                    </Typography>

                    <div style={styles.containerBtns}>
                        <Button onClick={changeOderApiShip} variant="contained" color="success">{
                            createOrderApiShip ? 'Удалить' : 'Создать'}</Button>
                        <Button onClick={handleCloseModal} variant="contained" color="error">Закрыть</Button>
                    </div>
                </>
            )}
        </Box>
    </Modal>
);

const styles = {
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    containerBtns: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    containerLoader: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
    },
};