import {
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams
} from 'react-admin';

import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';

import { User } from '@interfaces/users';
import { Collections } from '@enum/enum';

import Parse from 'parse';
import getPaginationData from '@utils/pagination';
import ParseHelper from './ParseHelper';

export type ParamsCreateCategory = User;

const attributes: Array<keyof User> = [
    'id',
    'firstName',
    'phone',
    'lastName',
    'bonusAmount',
    'middleName',
    'email',
    'username',
    'cityName',
    'isAllowCashOnDelivery'
];

@staticImplements<Pick<OverrideDataProvider<User>, 'create' | 'getList' | 'update'>>()
export default class UsersClass {
    static async getList(
        _: string,
        { pagination, }: GetListParams
    ): Promise<GetListResult<User>> {
        const usersQuery = new Parse.Query(Parse.User);

        const users = (await usersQuery.find({ useMasterKey: true, }))
            .map((user) => (ParseHelper.ExtractAttributes<User, keyof User>(
                user, attributes
            )));

        return getPaginationData<User>(pagination, users);
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<User>> {
        const user = (await (new Parse.Query(Parse.User).get(String(id), { useMasterKey: true, })));

        return {
            data: (
                ParseHelper.ExtractAttributes<User, keyof User>(
                    user, attributes
                )
            ),
        };
    }

    static async create(
        _: string,
        { data, }: CreateParams<User>
    ): Promise<CreateResult<User>> {
        const createdUser = new Parse.Object(Collections.Users);
        await createdUser.save(data);

        return {
            data: { ...data, id: createdUser.id, } as User,
        };
    }

    static async update(
        _: string,
        { id, data, }: UpdateParams<User>
    ): Promise<any> {
        return {
            data: await ParseHelper.UpdateObject(
                data,
                true,
                Collections.Users,
                String(id),
                undefined,
                true
            ),
        };
    }

    static async deleteMany(
        _: string,
        { ids, }: DeleteManyParams
    ): Promise<DeleteManyResult> {
        const deletedUsers = await (new Parse.Query(Collections.Users).containedIn('objectId', ids)).find();

        await Promise.all(deletedUsers.map((user) => user.destroy({ useMasterKey: true, })));

        return {
            data: ids,
        };
    }
}
