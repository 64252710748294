import { CollectionsRus } from '@enum/enum';
import { UploadBulkFilesProps } from '@interfaces/bulk_products';

type NotificationMsgProps = {
    type: UploadBulkFilesProps['type'],
    countSuccessUploaded: number,
    countFailUploaded: number,
    isSuccess: boolean,
};

export const getTitle = (type: UploadBulkFilesProps['type']) => {
    switch (type) {
    case 'uploadingImages':
        return 'Изображение для нескольких товаров';
    case 'uploadFile':
        return 'Инструкция для нескольких товаров';
    case 'uploadingDensity':
        return 'Плотность для нескольких товаров';
    default:
        return '-';
    }
};

export const getAdditionalMessages = ({
    type,
    countSuccessUploaded,
    countFailUploaded,
    isSuccess,
}: NotificationMsgProps): string[] => {
    switch (type) {
    case 'uploadingImages':
        return [
            `${countSuccessUploaded} фотографий успешно загружены`,
            `${countFailUploaded} фотографий не были загружены`
        ];
    case 'uploadFile':
        return [
            `${countSuccessUploaded} файлов успешно загружены`,
            `${countFailUploaded} файлов не были загружены`
        ];
    case 'uploadingDensity':
        return isSuccess 
            ? [ 'Плотности успешно загружены' ]
            : [ 'Плотности не были загружены' ];
    default:
        return [ '' ];
    }
};

export const getResource = (type: UploadBulkFilesProps['type']) => {
    switch (type) {
    case 'uploadingImages':
        return CollectionsRus.BulkProductImages;
    case 'uploadFile':
        return CollectionsRus.BulkProductManuals;
    case 'uploadingDensity':
        return CollectionsRus.BulkProductDensity;
    default:
        return '';
    }
};
