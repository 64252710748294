import {
    EditProps,
    Edit,
    SimpleForm,
    TextInput,
    required,
    BooleanInput
} from 'react-admin';

const BrandEdit = (props: EditProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Название" source="name" validate={required()} />

            <TextInput label="Slug" source="slug" disabled />

            <TextInput label="Транслит" source="translit" disabled />

            <TextInput
                label="ID 1C"
                source="id_1c"
                validate={required()}
            />

            <BooleanInput
                label="Удален"
                source="isDeleted"
            />
        </SimpleForm>
    </Edit>
);

export default BrandEdit;
