import { OverrideDataProvider } from '@interfaces/global';
import { LastTelegramMessage } from '@interfaces/telegram';
import TelegramClass from '@parse_entity_utils/telegram';

const TelegramDataProvider: Pick<OverrideDataProvider<LastTelegramMessage>, 'getOne' | 'getList' | 'update' | 'create'> = {
    getList: TelegramClass.getList,
    getOne: TelegramClass.getOne,
    update: TelegramClass.update,
    create: TelegramClass.create,
};

export default TelegramDataProvider;
