import CompilationClass from '@parse_entity_utils/compilation';

import { Compilation } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

const CompilationProvider: Pick<OverrideDataProvider<Compilation>, 'create' | 'getList' | 'getOne' | 'update' | 'delete'> = {
    create: CompilationClass.create,
    getList: CompilationClass.getList,
    getOne: CompilationClass.getOne,
    update: CompilationClass.update,
    delete: CompilationClass.delete,
};

export default CompilationProvider;
