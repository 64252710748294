import {
    Datagrid,
    List,
    TextField,
    EditButton,
    ListProps
} from 'react-admin';

const TelegramList = (props: Partial<ListProps>) => (
    <List
        {...props}
    >
        <Datagrid>
            <TextField label='Ссылка на последний пост' source="lastMessageLink" />

            <EditButton label="" />
        </Datagrid>
    </List>
);

export default TelegramList;
