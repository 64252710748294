import BlogClass from '@parse_entity_utils/blogs';

import { Blog } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

const BlogDataProvider: Pick<OverrideDataProvider<Blog>, 'create' | 'update' | 'getList' | 'getOne'> = {
    create: BlogClass.create,
    update: BlogClass.update,
    getList: BlogClass.getList,
    getOne: BlogClass.getOne,
};

export default BlogDataProvider;
