import { CloudFunctionName } from '@enum/parse-cloud';
import { useLoading } from '@hooks/useLoading';
import { CalcRalCostAndContainersResult, ContainersData, RalOrderFormData } from '@interfaces/ral';
import { Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { filterContainers } from '@utils/ral-utils';
import { runCloud } from '@utils/run-cloud';
import { FC, useEffect, useReducer, useState } from 'react';
import { BooleanInput, FormDataConsumer, minValue, number, NumberInput, required, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import { ContainerTable } from './ContainerTable';
import { HardnerTable } from './HardnerTable';
import { TableWithComponents } from './tableWithComponents';

const getPlaceholder = (isWeight: boolean) => {
    if (isWeight) {
        return 'Введите желаемый вес краски (кг)';
    }
    return 'Введите желаемый объём краски (л)';
};

const isCalcButtonDisabled = ({
    isWeight,
    ralCodeId,
    needWeight = 0,
    needVolume = 0,
}: RalOrderFormData) => {
    if (!ralCodeId) {
        return true;
    }

    if (isWeight) {
        return needWeight < 0.001;
    }
    return needVolume < 0.001;
};

export const stylesForCostText = makeStyles(() => ({
    textContainer: {
        '& input, & textarea': {
            color: 'black',
            textAlign: 'center',
            '-webkit-text-fill-color': 'black !important',
            'white-space': 'pre',
        },
        minHeight: 'unset !important',
    },
}));

const initialState: CalcRalCostAndContainersResult = {
    totalCost: 0,
    containersForDelivering: {},
    containersForPreparing: {},
    containersForHardners: {},
    componentsWithWeight: [],
    needOrderValue: 0,
    hardenerValues: 0,
    msg: '',
    totalVolume: 0,
    canCreateOrder: false,
    hardner: null,
};

const reducer = (state: CalcRalCostAndContainersResult, newData: Partial<CalcRalCostAndContainersResult>) => {
    return { ...state, ...newData, };
};

const CalcCostBtn: FC = () => {
    const form = useForm();

    const {
        isLoading,
        callWithLoading,
    } = useLoading(false);

    const [
        {
            hardner,
            totalCost,
            // totalVolume,
            containersForDelivering,
            containersForPreparing,
            containersForHardners,
            // needOrderValue,
            msg,
            componentsWithWeight,
            canCreateOrder,
        },
        dispatch
    ] = useReducer(reducer, initialState);

    const notify = useNotify();
    const styles = stylesForCostText();

    useEffect(() => {
        form.change('disableConfirm', !canCreateOrder);
    }, [ canCreateOrder ]);

    useEffect(() => {
        form.change('isLoading', isLoading);
    }, [ isLoading ]);
    
    const onCalcCost =  async (formData: RalOrderFormData) => {
        const costResult = await callWithLoading(async () => runCloud(
            CloudFunctionName.CalcRalCostAndContainers,
            formData
        )) as CalcRalCostAndContainersResult | string;

        if (typeof costResult === 'string') {
            dispatch({ canCreateOrder: false, });

            // error
            notify(
                costResult,
                'error',
                undefined,
                undefined,
                3000
            );
        } else {
            const { containersForDelivering, containersForPreparing, containersForHardners, } = costResult;
            // cost
            dispatch({
                ...costResult,
                containersForDelivering: filterContainers(containersForDelivering),
                containersForPreparing: filterContainers(containersForPreparing),
                containersForHardners: filterContainers(containersForHardners),
            });
        }
    };

    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, }) => {
                const { isWeight, needWeight, needVolume, } = formData;

                return (
                    <Container style={{ display: 'flex', flexFlow: 'column', }}>
                        <BooleanInput label="Объём | Масса" source="isWeight" />
                  
                        <NumberInput
                            label={isWeight ? 'Масса (кг)' : 'Объём (л)'}
                            placeholder={getPlaceholder(isWeight)}
                            source={isWeight ? 'needWeight' : 'needVolume' }
                            validate={[ required(), number(), minValue(0.001) ]}
                        />

                        {Boolean(msg) && (
                            <Typography
                                sx={{ flex: '1 1 100%', }}
                                component="h6"
                                color="red"
                                style={{ whiteSpace: 'pre', }}
                            >
                                {msg}
                            </Typography>
                        )}

                        {Boolean(totalCost) && (
                            <TextField
                                disabled
                                value={`${totalCost} руб.`}
                                className={styles.textContainer}
                            />
                        )}

                        <TableWithComponents
                            components={componentsWithWeight}
                        />

                        <HardnerTable
                            hardner={hardner}
                        />

                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <ContainerTable
                                containers={containersForDelivering}
                                type="delivering"
                            />

                            <ContainerTable
                                containers={containersForPreparing}
                                type="preparing"
                            />

                            <ContainerTable
                                containers={containersForHardners}
                                type="hardnes"
                            />
                        </div>
                            
                        <Button
                            disabled={isLoading || isCalcButtonDisabled(formData)}
                            onClick={() => onCalcCost(formData)}
                        >
                            {isLoading ? <CircularProgress color="secondary" /> : 'Рассчитать стоимость'}
                        </Button>
                    </Container>
                );
            }}
        </FormDataConsumer>
    );
};

export default CalcCostBtn;