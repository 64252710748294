import {
    CreateParams,
    CreateResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import Parse from 'parse';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Collections } from '@enum/enum';
import getPaginationData from '@utils/pagination';
import { Ral } from '@interfaces/ral';
import ParseHelper from './ParseHelper';

const attributes: Array<keyof Ral> = [
    'id',
    'category',
    'components',
    'name',
    'code',
    'koefWeightIn10L'
];

@staticImplements<Pick<OverrideDataProvider<Ral>, 'getList' | 'update' | 'create'>>()
export default class RalClass {
    private static UpdateComponents(
        components: Ral['components'],
        koefWeightIn10L: number
    ) {
        return components.map((component) => ({
            ...component,
            needWeightFor10L: Number((koefWeightIn10L * component.needWeightFor10KG / 10).toFixed(3)),
        }));
    }

    static async getList(
        _: string,
        { pagination, filter, sort, }: GetListParams
    ): Promise<GetListResult<Ral>> {
        let ralQuery = new Parse.Query(Collections.RAL).descending('createdAt');

        [ 'name', 'code' ].forEach((filterKey) => {
            const filterValue = (filter ?? {})[filterKey];
            if (filterValue) {
                ralQuery = ParseHelper.GetQueryForSearch(filterKey, filterValue, ralQuery);
            }
        });

        ralQuery = ParseHelper.GetSortQuery(ralQuery, sort);

        const result = await ParseHelper.GetDataWithPagination(
            pagination,
            ralQuery,
            attributes
        );

        return result;
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Ral>> {
        const ral = await ParseHelper.GetObject(id, Collections.RAL);

        return {
            data: ParseHelper.ExtractAttributes<Ral, keyof Ral>(
                ral, attributes
            ),
        };
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<Ral>
    ): Promise<UpdateResult<Ral>> {
        const { components, koefWeightIn10L, } = data;
        const updatedComponents = RalClass.UpdateComponents(components, koefWeightIn10L);
        return ParseHelper.UpdateObject({ ...data, components: updatedComponents, }, true, Collections.RAL, String(id));
    }

    static async create(
        _: string,
        { data, }: CreateParams<Ral>
    ): Promise<CreateResult<Ral>> {
        const ralObject = new Parse.Object(Collections.RAL);

        const { components, koefWeightIn10L, } = data;
        const updatedComponents = RalClass.UpdateComponents(components, koefWeightIn10L);

        await ralObject.save({ ...data, components: updatedComponents, });

        return {
            data: { ...data, id: ralObject.id, } as Ral,
        };
    }

    static async getMany(
        _: string,
        { ids, }: GetManyParams
    ): Promise<GetManyResult<Ral>> {
        return ParseHelper.GetManyEntities(ids, Collections.RAL, attributes);
    }
}
