import { ListIndomixTypes } from '@constants/ral';
import { CollectionsRus } from '@enum/enum';
import useCustomNotify from '@hooks/useNotify';

import {
    ShowProps,
    Create,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    number,
    NumberInput,
    SaveButton,
    Toolbar,
    minValue
} from 'react-admin';
import RalComponents from './ralComponents';
import { CopyFromRal } from './copyFromRal';
import { validator } from './validator';
import { useStyles } from './ralStyles';

const ToolbarRal = (props: any) => (
    <Toolbar>
        <SaveButton {...props} mutationOptions="" />
    </Toolbar>
);

const RALCreate = (props: Partial<ShowProps>) => {
    const classes = useStyles();

    const {
        onSuccess,
        onFailure,
    } = useCustomNotify(CollectionsRus.RAL, 'edit', 'create');

    const transform = ({ ralCodeId, ...data }: any) => (data);

    return (
        <Create
            onSuccess={onSuccess}
            onFailure={onFailure}
            transform={transform}
            {...props}
        >
            <SimpleForm
                toolbar={<ToolbarRal />}
                validate={validator}
            >
                <CopyFromRal />

                <TextInput
                    label="Название"
                    source="name"
                    validate={[
                        required()
                    ]}
                />
    
                <TextInput
                    label="Код"
                    source="code"
                    validate={[
                        required(),
                        number()
                    ]}
                />

                <NumberInput
                    label="В 10-ти литрах - (...) кг"
                    source="koefWeightIn10L"
                    step={0.001}
                    validate={[
                        required(),
                        number(),
                        minValue(0.001)
                    ]}
                    onWheel={(e) => (e.target as any).blur()}
                    className={classes.newLines}
                />
    
                <SelectInput
                    source='category'
                    label="Категория"
                    choices={ListIndomixTypes}
                    validate={required()}
                />
    
                <RalComponents mode='input' />
            </SimpleForm>
        </Create>
    );
};

export default RALCreate;
