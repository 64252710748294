import { CollectionsRus } from '@enum/enum';
import { Admin, Resource } from 'react-admin';

import './css/table.css';
import { CategoriesList, CategoryCreate, CategoryEdit, CategoryShow } from '@components/categories';
import { ProductsList, ProductEdit, ProductCreate, ProductShow } from '@components/products';
import localeAdapter from '@utils/localeAdapter';
import {
    OrdersEdit,
    OrdersList,
    OrdersShow
} from '@components/orders';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ShowLogs } from '@components/logs/ShowLogs';
import LogsList from '@components/logs/TypeLogs';

import { BlogGroupsCreate, BlogGroupsEdit, BlogGroupsList } from '@components/blogGroups';
import { RALOrderList, RALOrderCreate, RALOrderShow } from '@components/ralOrder';
import {
    BrandCreate,
    BrandEdit,
    BrandList
} from '@components/brand';

import {
    ConsultationList,
    ConsultationShow,
    ConsultationEdit
} from '@components/consultation';

import {
    UsersList,
    UsersShow,
    UsersCreate,
    UsersEdit
} from '@components/users';
import {
    CompilationCreate,
    CompilationEdit,
    CompilationList,
    CompilationShow
} from '@components/compilation';
import {
    BlogsList,
    BlogsCreate,
    BlogsEdit,
    BlogsShow
} from '@components/blogs';
import {
    RALCreate,
    RALEdit,
    RALList
} from '@components/ral';

import {
    TelegramList,
    TelegramCreate,
    TelegramEdit
} from '@components/telegram';

import { authProvider, dataProvider } from './dataProviders';

import './css/rich-text.css';

const App = () => (
    <Admin
        authProvider={authProvider as any}
        dataProvider={dataProvider}
        i18nProvider={localeAdapter}
    >
        <Resource
            name={CollectionsRus.Consultation}
            list={ConsultationList}
            show={ConsultationShow}
            edit={ConsultationEdit}
        />

        <Resource
            name={CollectionsRus.Brands}
            list={BrandList}
            edit={BrandEdit}
            create={BrandCreate}
        />

        <Resource
            name={CollectionsRus.Orders}
            list={OrdersList}
            show={OrdersShow}
            edit={OrdersEdit}
            icon={LocalShippingIcon}
        />

        <Resource
            name={CollectionsRus.Users}
            list={UsersList}
            show={UsersShow}
            create={UsersCreate}
            edit={UsersEdit}
        />

        <Resource
            name={CollectionsRus.Categories}
            list={CategoriesList}
            show={CategoryShow}
            edit={CategoryEdit}
            create={CategoryCreate}
        />

        <Resource
            name={CollectionsRus.Products}
            list={ProductsList}
            show={ProductShow}
            edit={ProductEdit}
            create={ProductCreate}
        />

        <Resource
            name={CollectionsRus.Logs}
            show={ShowLogs}
            list={LogsList}
        />

        <Resource
            name={CollectionsRus.BlogGroups}
            list={BlogGroupsList}
            create={BlogGroupsCreate}
            edit={BlogGroupsEdit}
        />

        <Resource
            name={CollectionsRus.Blogs}
            list={BlogsList}
            create={BlogsCreate}
            edit={BlogsEdit}
            show={BlogsShow}
        />

        <Resource
            name={CollectionsRus.Compilation}
            list={CompilationList}
            create={CompilationCreate}
            edit={CompilationEdit}
            show={CompilationShow}
        />

        <Resource
            name={CollectionsRus.RAL}
            list={RALList}
            create={RALCreate}
            edit={RALEdit}
        />

        <Resource
            name={CollectionsRus.RALOrder}
            list={RALOrderList}
            create={RALOrderCreate}
            show={RALOrderShow}
        />

        <Resource
            name={CollectionsRus.Telegram}
            list={TelegramList}
            create={TelegramCreate}
            edit={TelegramEdit}
        />
    </Admin>
);

export default App;
