import {
    Create,
    SimpleForm,
    TextInput,
    CreateProps,
    required
} from 'react-admin';

const BrandCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Название"
                source="name"
                validate={required()}
            />

            <TextInput
                label="ID 1C"
                source="id_1c"
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export default BrandCreate;
