import useScroll from '@hooks/useScroll';
import React, { FC } from 'react';
import { Datagrid, EditButton, FilterProps, List, ListProps, TextField, TextInput, Filter, BooleanField, DeleteButton } from 'react-admin';
import { styles } from '@constants/styles';

const BlogsFilter = (props: Partial<FilterProps>) => {
    const scroll = useScroll();

    return (
        <div style={styles(scroll) as React.CSSProperties}>
            <Filter {...props}>
                <TextInput label="Поиск" source="name" alwaysOn />
            </Filter>
        </div>
    );
};

const BlogGroupsList: FC<ListProps> = (props) => (
    <List
        {...props}
        filters={<BlogsFilter />}
    >
        <Datagrid>
            <TextField
                source='name'
                label="Название"
            />

            <BooleanField
                label="Удалена"
                source="isDeleted"
            />

            <EditButton />

            <DeleteButton />
        </Datagrid>
    </List>
);

export default BlogGroupsList;
