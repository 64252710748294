import { Identifier, PaginationPayload, Record as RARecord, SortPayload } from 'react-admin';

import Parse from 'parse';

import { Collections } from '@enum/enum';
import { filterEmptyImages, findPhoto, mapArrayOfPhotoObjectsToArrayOfUploadedPhotos, sortEntities } from '@utils/index';
import { ArrayOfUploadedPhotos, BlobObject, PhotoObject, TmpFileData, TmpPhotoData } from '@interfaces/file';
import { definePhotosActions, uploadPhoto } from '@utils/photosUtils';
import { Category, Product, UploadImagesParams } from '@interfaces/entities';
import prepareRegExpForSearch from '@utils/prepareNameForSearch';
import { executeActionForFile } from '@utils/fileUtils';
import { runCloud } from '@utils/run-cloud';
import { CloudFunctionName } from '@enum/parse-cloud';
import { OverridedProduct } from './products';
import { OverridedCategory } from './categories';

export default class ParseHelper {
    private static async SendImagesForHandling(
        collection: Collections,
        entityId: string,
        images: UploadImagesParams['images'],
        resetUseWatermark: boolean
    ) {
        const params: UploadImagesParams = {
            collection,
            entityId,
            images,
            resetUseWatermark,
        };

        await runCloud(CloudFunctionName.UploadImages, params);
    }

    static ExtractAttributes<T, K extends keyof T>(
        entity: Parse.Object<Parse.Attributes>,
        keys: K[],
        keyAsId?: K
    ): T {
        return (keys as string[]).reduce((resultObject, key) => {
            const handledKey = key as K;
            const value = entity.get(handledKey as string);

            switch (key) {
            case 'id':
                resultObject[handledKey] = (keyAsId ? entity.get(keyAsId as string) : entity.id) as any;
                break;
            case 'photos':
                if ([ Collections.Products, Collections.Categories ].includes(entity.className as Collections)) {
                    (resultObject as any).placeholder = `${process.env.REACT_APP_URL ?? ''}/placeholder.png`;
                    (resultObject as any).previewImg = findPhoto(value)?.webpIcon;
                }
                (resultObject as any).originalPhotos = value;
                resultObject[handledKey] = mapArrayOfPhotoObjectsToArrayOfUploadedPhotos(value) as any;
                break;
            case 'manual':
                if (value) {
                    (resultObject as any)[handledKey] = {
                        rawFile: value,
                        title: 'Инструкция к товару',
                        src: value,
                    } as TmpFileData;
                }
                break;
            case 'updatedAt':
                resultObject[handledKey] = entity.updatedAt as any;
                break;
            case 'sizeX':
            case 'sizeY':
            case 'sizeZ':
                resultObject[handledKey] = value || 1;
                break;
            case 'weight':
                resultObject[handledKey] = value || 0.001;
                break;
            case 'preview':
                resultObject[handledKey] = value?.image ?? '';
                break;
            default:
                resultObject[handledKey] = value;
                break;
            }

            return resultObject;
        }, {} as T);
    }

    static async UpdateObject<T>(
        objectWithNewData: T,
        partialUpdate: boolean,
        collection?: Collections,
        id?: string,
        savedObject?: Parse.Object,
        needUseMasterKey?: boolean
    ) {
        const updatedObject = id && collection
            ? await ParseHelper.GetObject(id, collection)
            : savedObject;
        
        if (!updatedObject) {
            throw new Error('Object is empty');
        }

        if (!partialUpdate) {
            updatedObject.clear({});
        }

        Object.entries(objectWithNewData).forEach(([ key, value ]) => {
            updatedObject.set(key, value);
        });

        needUseMasterKey ? await updatedObject.save(null, { useMasterKey: true, }) : await updatedObject.save();

        return {
            data: objectWithNewData,
            id: updatedObject.id,
        };
    }

    static async UpdateDataForCategoryOrProduct<T extends OverridedCategory | OverridedProduct, R extends Category | Product>(
        data: T,
        id: Identifier,
        previousData: RARecord,
        collection: Collections
    ) {
        delete (data as any).placeholder;
        delete (data as any).previewImg;

        const { resetUseWatermark, } = data as any;
        delete (data as any).resetUseWatermark;

        const { photos, originalPhotos = [], ...dataForSet } = data;

        const { manual, } = dataForSet as any;
        delete (dataForSet as any).manual;

        const currentObject = await ParseHelper.GetObject(id, collection);

        const handledPhotos = filterEmptyImages((data.photos as any) as ArrayOfUploadedPhotos);
        const {
            addedPhotos,
            deletedPhotos,
        } = definePhotosActions((previousData as T).originalPhotos ?? [], handledPhotos);

        const filteredPhotos = ParseHelper.FilterDeletedPhotos(
            deletedPhotos,
            originalPhotos,
            data?.photos ?? []
        );

        const filesHaveLargeSize: string[] = [];

        if (currentObject.className === Collections.Products) {
            if (typeof currentObject.get('description') === 'undefined') {
                currentObject.set('description', '');
            }

            if ('manual' in data) {
                const fileWithLargeSize = await executeActionForFile<Product>(
                    manual as TmpFileData,
                    (previousData.manual) as TmpFileData,
                    currentObject,
                    'manual'
                );

                if (fileWithLargeSize) {
                    filesHaveLargeSize.push(fileWithLargeSize);
                }
            }
        }

        currentObject.set('photos', filteredPhotos);
        await currentObject.save();

        const uploadedPhotos: string[] = [];
        const photosHaveLargeSize: string[] = [];
        if (addedPhotos.length) {
            const {
                resultUploadedPhotos,
                resultPhotosHaveLargeSize,
                resultUploadedPhotoNames,
            } = await uploadPhoto(currentObject, addedPhotos, 'photo.image', 'photo.title', false);
            uploadedPhotos.push(...resultUploadedPhotos);
            photosHaveLargeSize.push(...resultPhotosHaveLargeSize);

            await ParseHelper.SendImagesForHandling(
                collection,
                currentObject.id,
                resultUploadedPhotos.map((url, idx) => ({
                    url,
                    name: resultUploadedPhotoNames[idx],
                })),
                resetUseWatermark ?? false
            );
        }

        await ParseHelper.UpdateObject({ ...dataForSet, file: null, }, true, undefined, undefined, currentObject);

        return {
            data: data as any as R,
            uploadedPhotos,
            resultPhotosHaveLargeSize: photosHaveLargeSize,
            filesHaveLargeSize,
            manual: currentObject.get('manual'),
        };
    }

    static GetObject(id: Identifier, collection: Collections) {
        return (new Parse.Query(collection).get(String(id)));
    }

    static async DeleteOne<T>(id: Identifier, collection: Collections, previousData?: RARecord) {
        const entity = await ParseHelper.GetObject(id, collection);

        entity.set('isDeleted', true);
        await entity.save();

        return {
            data: previousData as any as T,
        };
    }

    static async DeleteMany<T>(ids: Identifier[], collection: Collections, attributes: Array<keyof T>) {
        const oldData: T[] = [];

        const entities = await (new Parse.Query(collection)
            .containedIn('objectId', ids)
            .findAll());

        entities.forEach((entity) => {
            if (entity) {
                oldData.push(
                    ParseHelper.ExtractAttributes<T, keyof T>(
                        entity, attributes
                    )
                );
                entity.set('isDeleted', true);
            }
        });

        await Parse.Object.saveAll(entities);

        return {
            data: ids as string[],
        };
    }

    static async CreateObject<T extends { photos: ArrayOfUploadedPhotos }>(
        data: T, collection: Collections
    ) {
        delete (data as any).placeholder;
        delete (data as any).previewImg;

        const { resetUseWatermark, } = data as any;
        delete (data as any).resetUseWatermark;

        const parseObject = new Parse.Object(collection);

        const { photos, ...entityData } = data;
        const { manual, } = entityData as any;
        delete (entityData as any).manual;

        const filesHaveLargeSize: string[] = [];

        if (parseObject.className === Collections.Products) {
            if ('manual' in data) {
                const fileWithLargeSize = await executeActionForFile<Product>(
                    manual,
                    null,
                    parseObject,
                    'manual'
                );

                if (fileWithLargeSize) {
                    filesHaveLargeSize.push(fileWithLargeSize);
                }
            }
        }

        await parseObject.save(entityData);
        
        const handledPhotos = filterEmptyImages(photos);
        const {
            resultPhotosHaveLargeSize,
            resultUploadedPhotoNames,
            resultUploadedPhotos,
        } = await uploadPhoto(parseObject, handledPhotos, 'photo.image', 'photo.title', false);

        await ParseHelper.SendImagesForHandling(
            collection,
            parseObject.id,
            resultUploadedPhotos.map((url, idx) => ({
                url,
                name: resultUploadedPhotoNames[idx],
            })),
            resetUseWatermark ?? false
        );

        return {
            data: { id: parseObject.id, photos, ...entityData, } as any,
            parseObject,
            resultPhotosHaveLargeSize,
            filesHaveLargeSize,
        };
    }

    static FilterDeletedPhotos(
        deletedPhotos: string[],
        existedPhotos: PhotoObject[],
        currentPhotos: ArrayOfUploadedPhotos
    ): PhotoObject[] {
        const filteredPhotos = existedPhotos
            .filter(({ image: imageUrl, }) => !deletedPhotos.includes(imageUrl));

        return filteredPhotos.map((photo) => ({
            ...photo,
            isMain: currentPhotos.find(({ photo: { image: imageUrl, }, }) => imageUrl === photo.image)?.isMain ?? false,
        }));
    }

    static GetSortQuery(
        query: Parse.Query,
        sort: SortPayload
    ) {
        if (sort !== undefined) {
            const { field, order, } = sort;

            const sortedField = (query.className === Collections.Products && field === 'name') ? 'nameLowerCase' : field;
            if (order !== 'ASC') {
                query = query.addAscending(sortedField);
            } else {
                query = query.addDescending(sortedField);
            }
        }

        return query;
    }

    static async GetDataWithPagination<T>(
        pagination: PaginationPayload,
        query: Parse.Query,
        attributes: Array<keyof T>
    ) {
        const { page, perPage, } = pagination;
        const start = (page - 1) * perPage;

        const [ totalCount, entities ] = await Promise.all([
            query.count(),
            query.skip(start).limit(perPage).find()
        ]);

        return {
            data: entities.map((entity) => (
                ParseHelper.ExtractAttributes<T, keyof T>(entity, attributes)
            )),
            total: totalCount,
        };
    }

    static GetQueryForSearch<T>(
        field: keyof T,
        value: string,
        query: Parse.Query
    ) {
        if (value !== undefined) {
            query = query.matches(
                field as string,
                new RegExp(prepareRegExpForSearch(value)),
                'i'
            );
        }
        return query;
    }

    static async GetAllSortedEntitiesForDropdown<T extends { name: string }>(
        query: Parse.Query,
        attributes: Array<keyof T>,
        keyAsId?: keyof T
    ) {
        const entities = (await query.findAll())
            .map((entity) => (
                ParseHelper.ExtractAttributes<T, keyof T>(
                    entity, attributes, keyAsId
                )
            ));
        const sortedEntities = sortEntities(entities);
        return {
            data: sortedEntities,
            total: sortedEntities.length,
        };
    }

    static async GetOneEntity<T>(
        id: Identifier,
        collection: Collections,
        attributes: Array<keyof T>,
        keyAsId?: keyof T
    ) {
        const entity = await ParseHelper.GetObject(id, collection);

        return {
            data: ParseHelper.ExtractAttributes<T, keyof T>(entity, attributes, keyAsId),
        };
    }

    static async GetManyEntities<T>(
        ids: Identifier[],
        collection: Collections,
        attributes: Array<keyof T>,
        keyAsId?: keyof T
    ) {
        const entities = await (
            new Parse.Query(collection).containedIn('objectId', ids)
        ).findAll();

        return {
            data: entities.map((entity) => (
                ParseHelper.ExtractAttributes<T, keyof T>(entity, attributes, keyAsId)
            )) as T[],
        };
    }

    static async UploadImages(
        data: any,
        parseObject: Parse.Object,
        arrayUploadedImages: Array<'preview' | 'cover'> = [ 'preview', 'cover' ]
    ) {
        const issuesWithLargeSize: string[] = [];

        if (data.preview) {
            (data as any).preview = {
                blobUrl: data.preview,
                title: 'preview.png',
            } as BlobObject;
        }
        await Promise.all(arrayUploadedImages.map(async (field) => {
            const {
                resultPhotosHaveLargeSize,
                resultUploadedPhotos,
            } = await uploadPhoto(
                parseObject,
                [ data[field as 'cover' | 'preview'] ] as Array<BlobObject> | Array<TmpPhotoData>,
                field === 'preview' ? 'blobUrl' : 'image',
                'title',
                false
            );
    
            if (resultUploadedPhotos.length === 1) {
                parseObject.set(
                    field,
                    {
                        image: resultUploadedPhotos[0],
                        webpImage: '',
                    }
                );
            }

            if (!resultPhotosHaveLargeSize.length) {
                issuesWithLargeSize.push(...resultPhotosHaveLargeSize);
            }
        }));

        return issuesWithLargeSize;
    }
}