import { PaginationPayload } from 'react-admin';

const getPaginationData = <T>(
    { page, perPage, }: PaginationPayload,
    data: T[]
) => {
    const pageStart = (page - 1) * perPage;
    const pageEnd = pageStart + perPage;
    const dataPage = data.slice(pageStart, pageEnd) as T[];
    const total = data.length;

    return {
        data: dataPage,
        total,
    };
};

export default getPaginationData;
