import { useState, MouseEvent, useEffect, useRef } from 'react';
import { Button, Container, Popover } from '@mui/material';
import { CollectionsRus } from '@enum/enum';
import { useForm } from 'react-final-form';
import {
    ReferenceInput,
    required,
    AutocompleteInput,
    useDataProvider
} from 'react-admin';
import awaiter from '@utils/awaiter';
import { RalComponents } from '@interfaces/ral';

export const CopyFromRal = (props: any) => {
    const dataProvider = useDataProvider();
    const form = useForm();

    const [ anchorEl, setAnchorEl ] = useState<Element | null>(null);
    const savedCodeRalId = useRef<string | null>(null);

    const setComponents = async (components: RalComponents[]) => {
        for (let idx of Array.from(Array(components.length).keys())) {
            form.change('components', components.slice(0, idx + 1));
            await awaiter(1500);
        }
    };

    const setValuesFromCopiedForm = async (newRalId: string | null) => {
        if (newRalId) {
            const ralData = await dataProvider.getOne(CollectionsRus.RAL, { id: newRalId, });

            if (ralData.data) {
                Object.entries(ralData.data).forEach(async ([ key, value ]) => {
                    if (key === 'components') {
                        setComponents(value);
                    } else if (key !== 'id') {
                        form.change(key, value);
                    }
                });
            }
        }
    };

    const ralCodeId = form.getFieldState('ralCodeId')?.value ?? null;
    if (ralCodeId) {
        savedCodeRalId.current = ralCodeId;
    }

    useEffect(() => {
        setValuesFromCopiedForm(savedCodeRalId.current);
    }, [ savedCodeRalId.current ]);

    const handleClickCopy = ({ currentTarget, }: MouseEvent) => {
        setAnchorEl(currentTarget);
    };

    return (
        <Container>
            <Button onClick={handleClickCopy}>
              Скопировать c RAL
            </Button>

            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <ReferenceInput
                    label="Код"
                    source="ralCodeId"
                    reference={CollectionsRus.RAL}
                    filterToQuery={searchText => ({ code: searchText, resetPagination: true, })}
                    validate={[ required() ]}
                >
                    <AutocompleteInput source='code' optionText="code" />
                </ReferenceInput>
            </Popover>
        </Container>
    );
};
