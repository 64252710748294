import { FC } from 'react';
import { useRecordContext } from 'react-admin';

import { Category } from '@interfaces/entities';

type ActionCategoryBtnProps = {
    Icon: any,
    needHideIcon?: {
        nameProps: keyof Category,
        pozitive: boolean,
    },
};

const ActionCategoryBtn: FC<ActionCategoryBtnProps> = ({
    Icon,
    needHideIcon,
}) => {
    const record = useRecordContext() as Category;
    const { id, } = record;

    const nameProps = needHideIcon?.nameProps;
    const pozitive = needHideIcon?.pozitive;

    return (
        <>
            {nameProps && (!pozitive ? record[nameProps] : !record[nameProps])
                ? <></>
                : <Icon record={{ id, }} label="" />}
        </>
    );
};

export default ActionCategoryBtn;
