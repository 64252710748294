import {
    Show,
    SimpleShowLayout,
    TextField,
    ShowProps,
    BooleanField,
    ImageField,
    ArrayField,
    Datagrid,
    RichTextField
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';

const styles: any = {
    image: { minWidth: '100%', },
};

type CategoryShowProps = {
    classes: object,
    permissions: any,
    props: Partial<ShowProps>,
};

const CategoryShow = (withStyles as any)(styles)(({ classes, permissions, ...props }: CategoryShowProps) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />

            <TextField label="Название" source="name" />

            <TextField
                label="ID 1C"
                source="id_1c"
            />

            <BooleanField label="Имеются подкатегории" source="hasSubcategories" />

            <RichTextField label="Meta описание" source="metaDescription" />

            <TextField label="Seo название" source="seoTitle" />

            <TextField label="Seo заголовок" source="seoHeader" />

            <RichTextField label="Seo описание" source="seoDescription" />

            <ArrayField label="Изображения категории" source="photos">
                <Datagrid>
                    <BooleanField label="Главное" source="isMain" />

                    <ImageField label="Изображение" classes={classes} source="photo.image" />
                </Datagrid>
            </ArrayField>

            <ArrayField label="Хлебная крошка" source="breadcrumbs">
                <Datagrid>
                    <TextField label="Название" source="name" />

                    <TextField label="Slug" source="slug" />
                </Datagrid>
            </ArrayField>
    
            <BooleanField label="Популярная" source="isPopular" />

            <BooleanField label="Опасная" source="isDangerous" />

            <BooleanField label="Удалена" source="isDeleted" />
        </SimpleShowLayout>
    </Show>
));

export default CategoryShow;
