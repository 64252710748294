import {
    EditProps,
    Edit,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

const TelegramEdit = (props: EditProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Ссылка на последний пост" source="lastMessageLink" validate={required()} />
        </SimpleForm>
    </Edit>
);

export default TelegramEdit;
