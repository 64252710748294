import {
    Datagrid,
    List,
    TextField,
    ListProps,
    ShowButton
} from 'react-admin';

const LogsList = (props: Partial<ListProps>) => (
    <List
        {...props}
    >
        <Datagrid>
            <TextField label='Тип лога' source="name" />

            <ShowButton label="" />
        </Datagrid>
    </List>
);

export default LogsList;
