import { useState, useRef, FC, useEffect } from 'react';
import { required, useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { Field, useField } from 'react-final-form';
import ReactCrop, { Crop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

import { getImageURL } from './provider';

const useStyles = makeStyles(() => ({
    imageInput: {
        // maxWidth: '256px',
    },
    imageContainer: {
        position: 'relative',
        display: 'block',
        width: '256px',
        minHeight: '50px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imageContainerImage: {
        width: '100%',
        display: 'block',
        backgroundColor: '#303030',
        padding: '5px',
    },
    imageContainerButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    hidden: {
        display: 'none',
    },
    cropContainer: {
        width: '100%',
        height: '100%',
    },
}));

const ImagePreview = (props: any) => {
    const classes = useStyles();
    let cropped = props.state.croppedImageUrl;
    let original = getImageURL(props.record);
    let src = cropped ? cropped : original;
    if (src) {
        return (
            <img src={src} alt="Preview" className={classes.imageContainerImage} />
        );
    }
    return <p>Нет превью фото</p>;
};

type EditableImageProps = {
    record?: any;
    source: string;
    keyPreview?: string;
    isRequired?: boolean;
    minWidth?: number;
    maxWidth?: number;
    disableAspect?: boolean;
    isEditMode?: boolean,
};

type StateType = {
    src: string | null,
    croppedImageUrl: string | undefined,
    fileUrl: string | undefined,
    blob: Blob | undefined,
    crop: Partial<Crop>,
};

export const EditableImage: FC<EditableImageProps> = ({
    record,
    source,
    isRequired = false,
    minWidth,
    maxWidth,
    isEditMode = false,
    keyPreview = 'preview',
}) => {
    const classes = useStyles();
    const {
        input: { onChange, value, },
    } = useField(source);

    const preview = useRecordContext()[keyPreview];

    // Contains the reference to the original image (objectURL)
    const imageRef = useRef<HTMLImageElement | null>(null);

    const [ state, setState ] = useState<StateType>({
        // Contains the new (non-cropped) image
        src: null,
        // Contains the reference to the cropped image (objectURL)
        croppedImageUrl: undefined,
        // Contains the actual cropped image blob
        blob: undefined,
        // Settings for the ReactCrop component. We want square images, so we set the aspect ratio to 1
        crop: {
            unit: '%',
            height: 100,
            width: isEditMode ? 100 : 50,
            x: isEditMode ? 0 : 25,
            y: 0,
            // aspect: 1,
        },
        fileUrl: undefined,
    });
    
    useEffect(() => {
        setState((prevState: StateType) => ({
            ...prevState,
            src: preview,
            croppedImageUrl: preview,
        }));
        onChange(preview);
    }, [ preview ]);

    const onSelectFile = ({ target: { files, }, }: any) => {
        if (files?.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setState((prevState: any) => ({
                    ...prevState,
                    src: reader.result,
                }))
            );
            reader.readAsDataURL(files[0]);
        }
    };

    const onImageLoaded = (image: HTMLImageElement) => {
        imageRef.current = image;
    };

    const getCroppedImg = (image: any, crop: any, fileName: string) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
        }
        // Return promise with the new (cropped) image. For this, a new object URL gets created.
        // Furthermore, the image blob gets stored in the state object.
        return new Promise((resolve) => {
            canvas.toBlob((blob: any) => {
                if (!blob) {
                    // eslint-disable-next-line no-console
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                let fileUrl = state.fileUrl;
                // if (fileUrl) {
                window.URL.revokeObjectURL(fileUrl!);
                fileUrl = window.URL.createObjectURL(blob);
                setState((prevState: any) => ({
                    ...prevState,
                    blob: blob,
                    fileUrl: fileUrl,
                }));
                // }
                resolve(fileUrl);
            }, 'image/png');
        });
    };

    const makeClientCrop = async (currentImageRef: HTMLImageElement, crop: Crop) => {
        if (currentImageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                currentImageRef,
                crop,
                'preview.png'
            );
            setState((prevState: any) => ({
                ...prevState,
                croppedImageUrl: croppedImageUrl,
            }));
        }
    };
    const onCropComplete = (crop: Crop) => {
        if (imageRef.current) {
            makeClientCrop(imageRef.current, crop);
        }
    };

    const onCropChange = (crop: Crop) => {
        setState((prevState: any) => ({
            ...prevState,
            crop: crop,
        }));
    };

    return (
        <div className={classes.imageInput}>
            {/*
                We need this react-final-form field because it handles the react-admin update process
                Each time the cropped image changes, the onChange() method gets called with the current image
                */}
            <Field
                name={source}
                component="input"
                onChange={onChange(state.croppedImageUrl)}
                className={classes.hidden}
                validate={isRequired ? required() : undefined}
            />
            <div className={classes.imageContainer}>
                {/*Image preview*/}
                <ImagePreview record={record} state={state} />

                {/*Hidden input for the image upload. We only accept png images*/}

                <br />
                <br />
                {/*Material design upload button*/}
                <label htmlFor="upload" />
            </div>
            {/*When the image is selected and loaded, the ReactCrop component can be shown*/}
            {state.src && (
                <ReactCrop
                    src={state.src}
                    crop={state.crop}
                    ruleOfThirds
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                    minWidth={minWidth}
                    className={classes.cropContainer}
                    maxWidth={maxWidth}
                />
            )}
            <input id="upload" type="file" onChange={onSelectFile} />
        </div>
    );
};

EditableImage.defaultProps = { addLabel: true, } as any;