import { FC, useEffect } from 'react';
import { useListContext, useRecordContext } from 'react-admin';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// @ts-ignore
import { History } from 'history';

import { overrideHrefOnCreateButton, simplifyDefineObjectsAreEqual } from '@utils/index';
import { extractFilterData } from '@utils/workerWithUrl';
import { CollectionsRus } from '@enum/enum';

type ProceedToSubcollectionProps = {
    direction: 'back' | 'next',
    history: History,
    prevField: string,
    nextField: string,
    nameFilteredField: string,
    canDoNextStep?: boolean,
    nextCollection?: CollectionsRus,
    prevCollection?: CollectionsRus,
    nameReturnedField?: string,
};

export const ProceedToSubcollection: FC<ProceedToSubcollectionProps> = ({
    history,
    direction,
    prevField,
    nextField,
    nameFilteredField,
    nameReturnedField,
    canDoNextStep,
    nextCollection,
    prevCollection,
}) => {
    const record = useRecordContext();

    const {
        filterValues,
        setFilters,
    } = useListContext();

    const handleProceedClick = () => {
        const generatedFilter: Record<string, string | null> = {};

        switch (direction) {
        case 'next':
            const currentPreviuosFiltered = filterValues.prevFilteredField
                ? filterValues.prevFilteredField.split(',')
                : [];

            if (currentPreviuosFiltered.length) {
                generatedFilter.prevFilteredField = [
                    ...currentPreviuosFiltered,
                    filterValues[nameFilteredField]
                ].join(',');
            } else {
                generatedFilter.prevFilteredField = filterValues[nameFilteredField] ?? null;
            }

            const filteredNextValue = record[nextField];
            generatedFilter[nameFilteredField] = filteredNextValue;

            if (!canDoNextStep) {
                history.location = {
                    pathname: nextCollection,
                    search: '',
                    state: '',
                    hash: `#${nextCollection}`,
                };
                if (nameReturnedField) {
                    generatedFilter[nameReturnedField] = filterValues[nameFilteredField] ?? '';
                }
            }
            break;
        case 'back':
            if (prevCollection) {
                if (nameReturnedField) {
                    const filteredPrevValue = filterValues[nameReturnedField];

                    if (filteredPrevValue) {
                        generatedFilter[nameFilteredField] = filteredPrevValue;
                    }
                }

                history.location = {
                    pathname: prevCollection,
                    search: '',
                    state: '',
                    hash: `#${prevCollection}`,
                };
            } else {
                if (!filterValues.prevFilteredField) {
                    generatedFilter[nameFilteredField] = null;
                } else {
                    const previousParents = [ ...filterValues.prevFilteredField.split(',') ];
                    const current = previousParents.pop();
    
                    filterValues.prevFilteredField = previousParents.join(',') || null;
                    generatedFilter[nameFilteredField] = current;
                }
            }
                
            break;
        default:
            break;
        }
        
        setFilters(generatedFilter, undefined);
    };

    useEffect(() => {
        // handle go back, go forward
        const currentFilter = extractFilterData(history.location.search);
        if (!simplifyDefineObjectsAreEqual(currentFilter, filterValues)) {
            setFilters(currentFilter ?? {}, undefined);
        }

        const refForCreate = (currentFilter ?? {})[nameFilteredField];
        overrideHrefOnCreateButton(refForCreate);
    }, [ history.location.search ]);

    return (
        <div onClick={handleProceedClick}>
            {direction === 'back' && <KeyboardArrowLeftIcon />}

            {direction === 'next' && <KeyboardArrowRightIcon />}
        </div>
    );
};