import ProductsClass from '@parse_entity_utils/products';

import { Product } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

const ProductDataProvider: Pick<OverrideDataProvider<Product>, 'getList' | 'create' | 'getOne' | 'update' | 'delete' | 'deleteMany' | 'updateMany' | 'getMany'> = {
    getList: ProductsClass.getList,
    create: ProductsClass.create,
    getOne: ProductsClass.getOne,
    update: ProductsClass.update,
    delete: ProductsClass.delete,
    deleteMany: ProductsClass.deleteMany,
    updateMany: ProductsClass.uploadMany,
    getMany: ProductsClass.getMany,
};

export default ProductDataProvider;
