import { makeStyles } from '@mui/styles';

export const stylesForDropZone = makeStyles(() => ({
    common: {
        '& div[data-testid="dropzone"]': {
            backgroundColor: '#80808030',
        },
    },
}));

export const stylesForImage = makeStyles(() => ({
    image: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'unset !important',
        backgroundColor: 'transparent',

        '& img': {
            height: 60,
            width: 80,
        },
    },
}));
