
import { Brand } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';
import BrandClass from '@parse_entity_utils/brand';

const BrandDataProvider: Pick<OverrideDataProvider<
Brand>, 'update' | 'getList' | 'getOne' | 'delete' | 'deleteMany' | 'create' | 'getMany'
> = {
    getList: BrandClass.getList,
    getOne: BrandClass.getOne,
    create: BrandClass.create,
    update: BrandClass.update,
    delete: BrandClass.delete,
    deleteMany: BrandClass.deleteMany,
    getMany: BrandClass.getMany,
};

export default BrandDataProvider;
