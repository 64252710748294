import Parse from 'parse';
import * as XLSX from 'xlsx';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import { CloudFunctionName } from '@enum/parse-cloud';
import { runCloud } from './run-cloud';

export default class ExportUtils {
    static async RunCloudFunction(
        name: CloudFunctionName.ExportExeclProducts | CloudFunctionName.GetUsers
    ) {
        const entities = await runCloud(name);
        return entities;
    }

    private static async DownloadXSLXFile<T>(
        entities: T[],
        nameFile: string
    ) {
        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(entities);

        XLSX.utils.book_append_sheet(wb, ws, nameFile);

        XLSX.writeFile(wb, `${nameFile}.xlsx`);
    }

    static async ExportProducts() {
        const products = await ExportUtils.RunCloudFunction(CloudFunctionName.ExportExeclProducts);

        if (typeof products === 'string'){
            return products;
        }
       
        ExportUtils.DownloadXSLXFile(products, 'ListProducts');
    }

    static async ExportUsersCSV() {
        const resultUsers = await ExportUtils.RunCloudFunction(CloudFunctionName.GetUsers);

        if (typeof resultUsers === 'string'){
            return resultUsers;
        }

        const {
            attributes,
            users,
        } = resultUsers;

        jsonExport(users, {
            headers: attributes, // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'users'); // download as 'users.csv` file
        });
    }

    static async ExportUsersXSLT() {
        const resultUsers = await ExportUtils.RunCloudFunction(CloudFunctionName.GetUsers);

        if (typeof resultUsers === 'string'){
            return resultUsers;
        }

        const {
            users,
        } = resultUsers;

        ExportUtils.DownloadXSLXFile(users, 'users');
    }
}