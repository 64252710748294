import React, { FC } from 'react';
import { BooleanField, ImageField, NumberField, RichTextField, Show, ShowProps, Tab, TabbedShowLayout, TextField } from 'react-admin';

const CompilationShow: FC<ShowProps> = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Основное">
                <TextField
                    label="ID"
                    source="id"
                />

                <TextField
                    source="title"
                    label="Название"
                />

                <TextField
                    source="link"
                    label="Ссылка"
                />

                <BooleanField
                    source="isDeleted"
                    label="Удален"
                />
            </Tab>

            <Tab label="Фото и текст">
                <RichTextField
                    source="description"
                    label="Описание"
                />

                <ImageField
                    source="preview"
                    label="Превью фото"
                />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default CompilationShow;