import { CollectionsRus } from '@enum/enum';
import useCustomNotify from '@hooks/useNotify';
import { stylesForDropZone } from '@styles/index';
import {
    Create,
    SimpleForm,
    TextInput,
    CreateProps,
    ArrayInput,
    BooleanInput,
    ImageInput,
    ImageField,
    SimpleFormIterator,
    required
} from 'react-admin';

const CategoryCreate = (props: CreateProps) => {
    const styles = stylesForDropZone();

    const {
        onSuccess,
        onFailure,
    } = useCustomNotify(CollectionsRus.Categories, 'edit', 'create');

    return (
        <Create
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <SimpleForm className={styles.common}>
                <TextInput
                    validate={required()}
                    defaultValue=""
                    label="Название"
                    source="name"
                />
    
                <TextInput
                    label="Meta описание"
                    source="metaDescription"
                    options={{ multiline: true, }}
                />
    
                <TextInput
                    label="Seo название"
                    source="seoTitle"
                />
    
                <TextInput
                    label="Seo заголовок"
                    source="seoHeader"
                />
    
                <TextInput
                    label="Seo описание"
                    source="seoDescription"
                    options={{ multiline: true, }}
                />
    
                <TextInput
                    label="ID 1C"
                    source="id_1c"
                    validate={required()}
                />
    
                <BooleanInput label="Популярная" source="isPopular" />

                <BooleanInput label="Опасная" source="isDangerous" />
    
                <BooleanInput
                    label="Не накладывать водяной знак"
                    source='resetUseWatermark'
                />

                <ArrayInput defaultValue={[]} source="photos">
                    <SimpleFormIterator>
                        <ImageInput source="photo" label="Изображения" accept="image/*" placeholder={<p>Перенесите изображения</p>}>
                            <ImageField source="image" title="title" />
                        </ImageInput>
    
                        {/* <BooleanInput label="Главное" source="isMain" defaultValue={false} /> */}
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default CategoryCreate;
