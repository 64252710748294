import { OverrideDataProvider } from '@interfaces/global';
import { Ral } from '@interfaces/ral';
import RalClass from '@parse_entity_utils/ral';

const RalDataProvider: Pick<OverrideDataProvider<Ral>, 'getOne' | 'getList' | 'update' | 'create' | 'getMany'> = {
    getList: RalClass.getList,
    getOne: RalClass.getOne,
    update: RalClass.update,
    create: RalClass.create,
    getMany: RalClass.getMany,
};

export default RalDataProvider;
