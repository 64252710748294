import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { ArrayInput, BooleanInput, Create, FileField, FileInput, ImageField, ImageInput, minValue, number, NumberInput, SimpleForm, SimpleFormIterator } from 'react-admin';
import ImageIcon from '@mui/icons-material/Image';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { CollectionsRus } from '@enum/enum';
import { stylesForDropZone } from '@styles/index';
import useCustomNotify from '@hooks/useNotify';
import { UploadBulkFilesProps } from '@interfaces/bulk_products';
import { getAdditionalMessages, getTitle, getResource } from './utils';

const UploadBulkFiles = ({
    props,
    type,
    source,
}: UploadBulkFilesProps) => {
    const styles = stylesForDropZone();

    const { selectedIds, } = props;

    const transform = (data: any) => ({
        ...data,
        ids: selectedIds,
    });

    const [ showUploadModal, setShowUploadModal ] = useState<boolean>(false);

    const onOpenModal = () => setShowUploadModal(true);

    const onCloseModal = () => setShowUploadModal(false);

    const {
        onSuccess: onSuccessCustom,
        onFailure,
    } = useCustomNotify(CollectionsRus.Products, 'list');

    const onSuccess = (result: any) => {
        const {
            countFailUploaded,
            countSuccessUploaded,
            isSuccess,
        } = result;

        const customMsg: string[] = getAdditionalMessages({
            type, isSuccess, countFailUploaded, countSuccessUploaded,
        });

        onSuccessCustom(result, customMsg);

        const countSecondForRead = countFailUploaded ? countFailUploaded * 2000 : 0;
        setTimeout(() => {
            window.location.reload();
            onCloseModal(); 
        }, countSecondForRead + 2000);
    };

    return (
        <>
            <Button variant="outlined" color="primary" aria-label="create" onClick={onOpenModal}>
                {type === 'uploadingDensity' && (
                    <DensityMediumIcon style={{ marginRight: '4px', }} />
                )}

                {type === 'uploadingImages' && (
                    <ImageIcon style={{ marginRight: '4px', }} />
                )}

                {type === 'uploadFile' && (
                    <UploadFileIcon style={{ marginRight: '4px', }} />
                )}
            </Button>
        
            <Dialog
                open={showUploadModal}
                onClose={onCloseModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{getTitle(type)}</DialogTitle>

                <DialogContent>
                    <Create
                        { ...props }
                        resource={getResource(type)}
                        transform={transform}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    >
                        <SimpleForm mutationMode="pessimistic" className={styles.common}>
                            {type === 'uploadingImages' && (
                                <>
                                    <BooleanInput
                                        label="Не накладывать водяной знак"
                                        source='resetUseWatermark'
                                    />

                                    <ArrayInput defaultValue={[]} source="photos">
                                        <SimpleFormIterator>
                                            <ImageInput
                                                source="photo"
                                                label="Изображения"
                                                accept="image/*"
                                                placeholder={<p>Перенесите изображения</p>}
                                            >
                                                <ImageField source="image" title="title" />
                                            </ImageInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </>
                            )}

                            {type === 'uploadFile' && (
                                <FileInput
                                    source={source ?? ''}
                                    label="Файл с инструкцией"
                                    accept="application/pdf"
                                >
                                    <FileField source="src" title="title" />
                                </FileInput>
                            )}

                            {type === 'uploadingDensity' && (
                                <NumberInput
                                    source="density"
                                    label="Плотность"
                                    step={0.001}
                                    validate={[ number(), minValue(0.001) ]}
                                />
                            )}
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UploadBulkFiles;
