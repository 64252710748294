import { EMethodShipping, PaymentMethod } from '@enum/order';
import { ArrayOfUploadedPhotos, PhotoObject } from '@interfaces/file';

const storageUrl = process.env.REACT_APP_STORAGE_URL ?? '';
const bucket = process.env.REACT_APP_BUCKET ?? '';

export const getParentRef = (ref: string) => {
    const reducedRef = ref.split('/');
    reducedRef.splice(-2, 2);

    return reducedRef.join('/');
};

export const reduceLastDocumentId = (ref: string) => {
    const reducedRef = ref.split('/');
    reducedRef.splice(-1, 1);

    return reducedRef.join('/');
};

export const isRootEntity = () => !/#.*\?/.test(window.location.hash);

export const reduceExtenstion = (fillNameFile: string) => {
    const clearName = fillNameFile?.split('.');
    clearName.pop();
    return clearName.join('');
};

export const extractRefSubcategoryFromFilter = (
    path: string
) => {
    const decodedUri = decodeURI(path);

    const [ extractedFilter ] = decodedUri.match(/filter=.*&?/) ?? [ null ];

    if (extractedFilter) {
        const [ refSubcategory ] = extractedFilter.match(/refSubcategory"%3A".*"/) ?? [ null ];
        if (refSubcategory) {
            return refSubcategory.replace(/(refSubcategory"%3A"|")/g, '');
        }
    }
    return null;
};

export const decodeSlash = (path: string) => path.replace(/%2F/g, '/');

export const getCreateBtn = () => (document.querySelector('[aria-label="Создать"]') as HTMLLinkElement);

export const overrideHrefOnCreateButton = (
    refCategoryFromUrl: string
) => {
    const createElement = getCreateBtn();

    if (createElement) {
        (createElement.style as any)['pointer-events'] = 'none';
    }
    setTimeout(() => {
        const currentCreateHref = createElement?.href ?? '';

        if (currentCreateHref) {
            const basePath = currentCreateHref.slice(0, currentCreateHref.indexOf('create') + 'create'.length);

            if (basePath) {
                if (refCategoryFromUrl) {
                    createElement.href = `${basePath}?refCategory=${refCategoryFromUrl}`;
                } else {
                    createElement.href = basePath;
                }

                createElement.onclick = (e: MouseEvent) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.location.href = createElement.href;
                };

                if (createElement) {
                    (createElement.style as any)['pointer-events'] = 'all';
                }
            }
        }
    }, 1000);
};

export const simplifyDefineObjectsAreEqual = (
    obj1: any, obj2: any
) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const filterEmptyImages = (photos: ArrayOfUploadedPhotos) => (photos ?? []).filter(({ photo, }) => photo);

export const mapArrayOfPhotoObjectsToArrayOfUploadedPhotos = (
    photos: PhotoObject[]
): ArrayOfUploadedPhotos => (
    photos.map(({ isMain, image, }) => ({
        isMain: isMain ?? false,
        photo: {
            rawFile: image,
            image,
        },
    })));

export const getShippingMethdod = (method: EMethodShipping) => {
    switch (method) {
    case EMethodShipping.API_SHIP:
        return 'API SHIP';
    case EMethodShipping.TAXI:
        return 'Яндекс.Такси';
    case EMethodShipping.PKUP:
        return 'Самовывоз';
    default:
        return '-';
    }
};

export const getPaymentMethdod = (method: PaymentMethod) => {
    switch (method) {
    case PaymentMethod.Credit:
        return 'Рассрочка';
    case PaymentMethod.OnDelivery:
        return 'При получении';
    case PaymentMethod.Online:
        return 'Онлайн';
    default:
        return '-';
    }
};

export const sortEntities = <T extends { name: string }>(entities: Array<T>) => (
    entities.sort(({ name: nameA, }, { name: nameB, }) => (nameA > nameB ? 1 : -1))
);

export const findPhoto = (photos: PhotoObject[]): PhotoObject | null => (
    photos?.find(({ isMain, }) => isMain) ?? (photos?.length ? photos[0] : null)
);

export const adaptFileUrl = (fileUrl: string) => {
    const storage = `${storageUrl}/`;
    return fileUrl.replace(storage, `${storage}${bucket}/`);
};

export const exctractError = (error: unknown) => (
    error instanceof Error ? error.message : JSON.stringify(error)
);
