import { CollectionsRus } from '@enum/enum';
import { useNotify, useRedirect } from 'react-admin';

type TypeNotify = 'create' | 'update';

const useCustomNotify = (
    entity: CollectionsRus,
    nextAction: string,
    type?: TypeNotify

) => {
    const notify = useNotify();
    const redirect = useRedirect();
    
    const notifyAboutLargeSize = (resultPhotosHaveLargeSize: string[]) => {
        if (Array.isArray(resultPhotosHaveLargeSize) && resultPhotosHaveLargeSize.length) {
            notify(
                resultPhotosHaveLargeSize.join('\n'),
                'error',
                undefined,
                undefined,
                3000,
                true
            );
        }
    };

    const onSuccess = (
        result: any,
        additionalNotification?: string[]
    ) => {
        const {
            data,
            resultPhotosHaveLargeSize = [],
            filesHaveLargeSize = [],
        } = result;

        const { id, } = data;

        if (additionalNotification?.length) {
            notify(
                additionalNotification.join('\n'),
                'info',
                undefined,
                undefined,
                2000,
                true
            );
        }

        notifyAboutLargeSize([ ...resultPhotosHaveLargeSize, ...filesHaveLargeSize ]);

        if (type) {
            notify(`Элемент ${type === 'create' ? 'создан' : 'обновлён'}`);
        }

        if (nextAction === 'edit') {
            redirect(nextAction, `/${entity}`, id, data);
        } else {
            redirect(nextAction, entity);
        }
    };

    const onFailure = (data: any) => {
        const {
            resultPhotosHaveLargeSize = [],
            filesHaveLargeSize = [],
        } = data;
        
        if (type) {
            notify('Произошла ошибка', 'error');
        }
        notifyAboutLargeSize([ ...resultPhotosHaveLargeSize, ...filesHaveLargeSize ]);
    };

    return {
        onSuccess,
        onFailure,
    };
};

export default useCustomNotify;
