import { Product } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';
import UsersClass from '@parse_entity_utils/users';
import { User } from '@interfaces/users';

const UsersDataProvider: Pick<OverrideDataProvider<User>, 'create' | 'getList' | 'update' | 'getOne' | 'deleteMany'> = {
    create: UsersClass.create,
    getList: UsersClass.getList,
    getOne: UsersClass.getOne,
    update: UsersClass.update,
    deleteMany: UsersClass.deleteMany,
};

export default UsersDataProvider;
