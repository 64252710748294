import useScroll from '@hooks/useScroll';
import React, { useEffect, useState } from 'react';
import {
    Datagrid,
    List,
    Filter,
    TextField,
    EditButton,
    DeleteButton,
    ListProps,
    FilterProps,
    TextInput,
    BooleanField
} from 'react-admin';
import { styles } from '@constants/styles';

const BrandsFilter = (props: Partial<FilterProps>) => {
    const scroll = useScroll();

    return (
        <div style={styles(scroll) as React.CSSProperties}>
            <Filter {...props}>
                <TextInput label="Поиск" source="name" alwaysOn />
            </Filter>
        </div>
    );
};

const BrandList = (props: Partial<ListProps>) => (
    <List
        {...props}
        filters={<BrandsFilter />}
    >
        <Datagrid>
            <TextField label='Название' source="name" />

            <TextField label='Slug' source="slug" />

            <TextField label='Транслит' source="translit" />

            <BooleanField label="Удалён" source="isDeleted" />

            <EditButton label="" />

            <DeleteButton label="" />
        </Datagrid>
    </List>
);

export default BrandList;
