import Tooltip from '@material-ui/core/Tooltip';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { ListProps, useRecordContext } from 'react-admin';

export const MyTooltip = ({
    props,
    customMessage,
}: {
    props?: Partial<ListProps>,
    customMessage?: string
}
) => {
    const { apiShipDescription, } = props ? useRecordContext(props) : { apiShipDescription: customMessage, };

    return (
        <Tooltip title={apiShipDescription.length ? apiShipDescription : 'Нет информации'}>
            <QuestionMarkIcon fontSize='small' />
        </Tooltip>
    );
};