import { DataProviderKeys, DataRequestedProviderKeys, OverrideDataProvider, TypeBulkProducts } from '@interfaces/global';

import { Collections, CollectionsRus, MapResourceObject } from '@enum/enum';

import CategoryDataProvider from '@dataProviders/categoryDataProvider';

import Parse from 'parse';
// @ts-ignore
import { AuthClient } from 'ra-parseserver-client';
import { LegacyDataProvider } from 'react-admin';
import BlogDataProvider from './blogDataProvider';
import BlogGroupsDataProvider from './blogGroupsProvider';
import ConsultationDataProvider from './consultationDataProvider';
import ProductDataProvider from './productDataProvider';

import BrandDataProvider from './brandDataProvider';
import OrderDataProvider from './orderDataProvider';
import UsersDataProvider from './usersDataProvider';
import LogsDataProvider from './logsDataProvider';
import CompilationProvider from './compilationProvider';
import RalDataProvider from './ralDataProvider';
import RalOrderDataProvider from './ralOrderDataProvider';
import TelegramDataProvider from './telegramDataProvider';

Parse.initialize(process.env.REACT_APP_APP_ID ?? '');
//javascriptKey is required only if you have it on server.
Parse.serverURL = process.env.REACT_APP_URL_SERVER ?? '';
Parse.masterKey = process.env.REACT_APP_MASTER_KEY ?? '';

export const parseConfig = {
    URL: process.env.REACT_APP_URL_SERVER,
    'APP-ID': process.env.REACT_APP_APP_ID,
    'REST-API-KEY': 'MyRestAPIKey',
    'MASTER-KEY': process.env.REACT_APP_MASTER_KEY,
};

const dataProviders: Record<Exclude<Collections, Collections.BulkProductImages | Collections.BulkProductManuals | Collections.BulkProductDensity>, Partial<OverrideDataProvider>> = {
    [Collections.Consultation]: ConsultationDataProvider,
    [Collections.Categories]: CategoryDataProvider,
    [Collections.Products]: ProductDataProvider,
    [Collections.BlogGroups]: BlogGroupsDataProvider,
    [Collections.Blogs]: BlogDataProvider,
    [Collections.Brands]: BrandDataProvider,
    [Collections.Orders]: OrderDataProvider,
    [Collections.Users]: UsersDataProvider,
    [Collections.Compilation]: CompilationProvider,
    [Collections.Logs]: LogsDataProvider,
    [Collections.RAL]: RalDataProvider,
    [Collections.RALOrder]: RalOrderDataProvider,
    [Collections.Telegram]: TelegramDataProvider,
};

const isBulkProducts = (resource: Collections) => (
    [
        Collections.BulkProductImages,
        Collections.BulkProductManuals,
        Collections.BulkProductDensity
    ].includes(resource)
);

const getBulkTypeForProduct = (resource: Collections): TypeBulkProducts | undefined => {
    switch (resource) {
    case Collections.BulkProductImages:
        return 'images';
    case Collections.BulkProductManuals:
        return 'file';
    case Collections.BulkProductDensity:
        return 'density';
    default:
        break;
    }
};

const delegate = (name: DataRequestedProviderKeys, resourceRus: CollectionsRus, params: any) => {
    const resource = MapResourceObject[resourceRus];

    const getNameFunction = (): DataProviderKeys => {
        switch (name) {
        case 'GET_LIST':
            return 'getList';
        case 'GET_ONE':
            return 'getOne';
        case 'GET_MANY':
            return 'getMany';
        case 'GET_MANY_REFERENCE':
            return 'getManyReference';
        case 'UPDATE':
            return 'update';
        case 'UPDATE_MANY':
            return 'updateMany';
        case 'CREATE':
            return 'create';
        case 'DELETE':
            return 'delete';
        case 'DELETE_MANY':
            return 'deleteMany';;
        default:
            return 'getList';
        }
    };

    const dataProvider = (isBulkProducts(resource))
        ? dataProviders['products']
        : dataProviders[resource as any as keyof (typeof dataProviders)];

    if (dataProvider) {
        if (isBulkProducts(resource)) {
            const func = dataProvider['updateMany'] as any;

            const type = getBulkTypeForProduct(resource);

            if (func && type) {
                return func(resource, { ...params, type, });
            }
        } else {
            const handledFunction = dataProvider[getNameFunction()];

            if (handledFunction) {
                return handledFunction(resource, params);
            }
        }
    }
    return {
        data: [],
    };
};

export const dataProvider: LegacyDataProvider = delegate as LegacyDataProvider;
export const authProvider = AuthClient(parseConfig);
