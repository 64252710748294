import useScroll from '@hooks/useScroll';
import React, { FC } from 'react';
import { BooleanField, Datagrid, EditButton, Filter, FilterProps, List, ListProps, ShowButton, TextField, TextInput } from 'react-admin';
import { styles } from '@constants/styles';

const CompilationFilter = (props: Partial<FilterProps>) => {
    const scroll = useScroll();

    return (
        <div style={styles(scroll) as React.CSSProperties}>
            <Filter {...props}>
                <TextInput label="Поиск" source="title" alwaysOn />
            </Filter>
        </div>
    );
};

const CompilationList: FC<ListProps> = (props) => (
    <List
        {...props}
        filters={<CompilationFilter />}
    >
        <Datagrid>
            <TextField
                source='id'
            />

            <TextField
                source='title'
                label="Название"
            />

            <BooleanField
                source='isDeleted'
                label="Удален"
            />

            <ShowButton />

            <EditButton />
        </Datagrid>
    </List>
);

export default CompilationList;
