export enum CloudFunctionName {
    ExportOrderXml = 'export-xml',
    ExportOrderPdf = 'export-pdf',
    OrderLabels = 'get-order-labels',
    AllLogs = 'get-all-log',
    ErrLogs = 'get-error-log',
    UploadImages = 'hande-uploading-images',
    ExportExeclProducts = 'export-excel',
    GetUsers = 'export-users',
    AddErrorLog = 'add-error-logs',
    CalcRalCostAndContainers = 'calc-ral-cost',
    CreateRalOrder = 'create-ral-order',
    ExportXMLRalOrder = 'export-xml-ral-order',
}
