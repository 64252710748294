import { FC } from 'react';
import { RalComponentsForOrder } from '@interfaces/ral';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { ComponentsHardnerHeader } from './ComponentsHardnerHeader';

type TableWithComponentsProps = {
    components: RalComponentsForOrder;
};

export const TableWithComponents: FC<TableWithComponentsProps> = ({
    components,
}) => {
    if (!components.length) {
        return <></>;
    }

    return (
        <div>
            <Typography
                sx={{ flex: '1 1 100%', }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Компоненты
            </Typography>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, }} aria-label="table with components">
                    <ComponentsHardnerHeader />

                    <TableBody>
                        {components.map(({ name, sku, totalResidueWeight = 0, needWeight, cost, }) => (
                            <TableRow
                                style={{ background: (needWeight <= totalResidueWeight ? 'white' : '#ffbaba'), }}
                                key={name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, }, }}
                            >
                                <TableCell component="th" scope="row">
                                    {name}
                                </TableCell>
                                <TableCell align="right">{sku}</TableCell>
                                <TableCell align="right">{cost}</TableCell>
                                <TableCell align="right">{needWeight}</TableCell>
                                <TableCell align="right">{totalResidueWeight}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
