import { EMethodShipping } from '@enum/order';
import { Order } from '@interfaces/orders';
import { FC } from 'react';
import { useRecordContext } from 'react-admin';
import { Button } from '@mui/material';
import Parse from 'parse';
import { CloudFunctionName } from '@enum/parse-cloud';
import { runCloud } from '@utils/run-cloud';

type OrderLabelsButtonProps = {
    onGenerateDownloadLink: (link: string) => void,
    onDownload: () => void,
};

const OrderLabelsButton: FC<OrderLabelsButtonProps> = ({
    onGenerateDownloadLink,
    onDownload,
}) => {
    const {
        createOrderInApiShip,
        shippingInfo: { method, },
        apiShipData,
    } = useRecordContext() as Order;

    const onGetOrderLabels = async () => {
        const options: Parse.Cloud.RunOptions = {
            sessionToken: localStorage.getItem('parseToken') ?? undefined,
        };

        const answerLabels = await runCloud(
            CloudFunctionName.OrderLabels,
            { orderId: String(apiShipData?.apiShipOrderId), }
        );

        if (answerLabels && typeof answerLabels === 'string') {
            onGenerateDownloadLink(answerLabels);
            onDownload();
        }
    };

    return (
        <>
            {method === EMethodShipping.API_SHIP && createOrderInApiShip && apiShipData?.apiShipOrderId && (
                <Button
                    style={{ width: '90%', marginTop: '10px', }}
                    variant="outlined"
                    onClick={onGetOrderLabels}
                >
                    Получить документы для отправки
                </Button>
            )}
        </>
    );
};

export default OrderLabelsButton;
