import {
    EditProps,
    BooleanInput,
    ArrayInput,
    Edit,
    ImageInput,
    SimpleForm,
    TextInput,
    ImageField,
    required,
    SimpleFormIterator,
    useRefresh
} from 'react-admin';

import { stylesForDropZone } from '@styles/index';
import useCustomNotify from '@hooks/useNotify';
import { CollectionsRus } from '@enum/enum';

const CategoryEdit = (props: EditProps) => {
    const styles = stylesForDropZone();

    const refresh = useRefresh();
    const {
        onSuccess: onSuccessCustom,
        onFailure,
    } = useCustomNotify(CollectionsRus.Categories, 'edit', 'update');

    const onSuccess = (data: any) => {
        onSuccessCustom(data);
        window.history.back();
        refresh();
    };

    return (
        <Edit
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
            {...props}
        >
            <SimpleForm className={styles.common}>
                <TextInput
                    validate={required()}
                    label="Название"
                    source="name"
                />

                <BooleanInput
                    label="Имеются подкатегории"
                    source='hasSubcategories'
                />

                <TextInput
                    label="Meta описание"
                    source="metaDescription"
                    options={{ multiline: true, }}
                />

                <TextInput
                    label="Seo название"
                    source="seoTitle"
                />

                <TextInput
                    label="Seo заголовок"
                    source="seoHeader"
                />

                <TextInput
                    label="Seo описание"
                    source="seoDescription"
                    options={{ multiline: true, }}
                />

                <TextInput
                    label="ID 1C"
                    source="id_1c"
                    validate={required()}
                />

                <BooleanInput
                    label="Не накладывать водяной знак"
                    source='resetUseWatermark'
                />

                <ArrayInput label="Добавить изображения" source="photos">
                    <SimpleFormIterator>
                        <ImageInput
                            source="photo"
                            label="Изображение"
                            accept="image/*"
                            placeholder={<p>Перенесите изображения</p>}
                        >
                            <ImageField source="image" title="title" />
                        </ImageInput>

                        <BooleanInput
                            label="Главное"
                            source="isMain"
                            defaultValue={false}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput label="Хлебная крошка" source="breadcrumbs" disabled>
                    <SimpleFormIterator>
                        <TextInput
                            validate={required()}
                            defaultValue=""
                            label="Название"
                            source="name"
                        />

                        <TextInput
                            validate={required()}
                            defaultValue=""
                            label="Slug"
                            source="slug"
                        />

                    </SimpleFormIterator>
                </ArrayInput>

                <BooleanInput label="Популярная" source="isPopular" />

                <BooleanInput label="Опасная" source="isDangerous" />

                <BooleanInput
                    label="Удалена"
                    source="isDeleted"
                />
            </SimpleForm>
        </Edit>
    );
};

export default CategoryEdit;
