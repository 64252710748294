import { EditableImage } from '@components/common/editableImage/EditableImage';
import { CollectionsRus } from '@enum/enum';
import useCustomNotify from '@hooks/useNotify';
import RichTextInput from 'ra-input-rich-text';
import React, { FC } from 'react';
import { Create, CreateProps, ImageField, ImageInput, NumberInput, SimpleForm, TextInput, required, SelectInput, BooleanInput, ReferenceInput, AutocompleteInput, number, minValue } from 'react-admin';
import { icons } from './constants/icons';

export const BlogsCreate: FC<CreateProps> = (props) => {
    const {
        onSuccess,
        onFailure,
    } = useCustomNotify(CollectionsRus.Blogs, 'edit', 'create');

    return (
        <Create
            {...props}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm>
                <TextInput
                    source='name'
                    label="Название"
                    validate={required()}
                />
    
                <ReferenceInput
                    label="Группа блога"
                    source="blogGroupSlug"
                    reference={CollectionsRus.BlogGroups}
                    filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                    validate={required()}
                >
                    <AutocompleteInput source='name' />
                </ReferenceInput>
    
                <ReferenceInput
                    label="Категория"
                    source="categorySlug"
                    reference={CollectionsRus.Categories}
                    allowEmpty
                    filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                >
                    <AutocompleteInput source='name' />
                </ReferenceInput>
    
                <NumberInput
                    source='timeToRead'
                    label="Время чтения (мин)"
                    validate={[ required(), number(), minValue(1) ]}
                />
    
                <RichTextInput
                    source='text'
                    label="Текст блога"
                    validate={required()}
                />
    
                <ImageInput
                    source="cover"
                    label="Изображение"
                    accept="image/*"
                    placeholder={<p>Перенесите изображения</p>}
                    validate={required()}
                >
                    <ImageField source="image" title="title" />
                </ImageInput>
    
                <EditableImage
                    {...props}
                    isRequired={true}
                    source="preview"
                    disableAspect={true}
                />
    
                {/* <NumberInput
                    disabled
                    source='views'
                    label="Просмотры"
                    defaultValue={0}
                    validate={required()}
                /> */}
    
                <SelectInput
                    source='themeIcon'
                    label="Значок темы"
                    choices={icons}
                    validate={required()}
                />
    
                <TextInput
                    source='seoTitle'
                    label="SEO Заголовок"
                    validate={required()}
                />
    
                <TextInput
                    source='seoDescription'
                    label="SEO описание"
                    validate={required()}
                />
            </SimpleForm>
        </Create>
    );
};

export default BlogsCreate;