import BlogGroupsClass from '@parse_entity_utils/blogGroups';

import { BlogGroups } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

const BlogGroupsDataProvider: Pick<OverrideDataProvider<BlogGroups>, 'create' | 'getList' | 'getOne' | 'update' | 'delete' | 'getMany'> = {
    create: BlogGroupsClass.create,
    getList: BlogGroupsClass.getList,
    getOne: BlogGroupsClass.getOne,
    update: BlogGroupsClass.update,
    delete: BlogGroupsClass.delete,
    getMany: BlogGroupsClass.getMany,
};

export default BlogGroupsDataProvider;
