import { EditableImage } from '@components/common/editableImage/EditableImage';
import { CollectionsRus } from '@enum/enum';
import useCustomNotify from '@hooks/useNotify';
import RichTextInput from 'ra-input-rich-text';
import React, { FC } from 'react';
import { AutocompleteInput, BooleanInput, Edit, EditProps, FormDataConsumer, ImageField, ImageInput, minValue, number, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useRefresh } from 'react-admin';
import { icons } from './constants/icons';

const BlogsEdit: FC<EditProps> = (props) => {
    const refresh = useRefresh();
    const {
        onSuccess: onSuccessCustom,
        onFailure,
    } = useCustomNotify(CollectionsRus.Blogs, 'edit', 'update');

    const onSuccess = (data: any) => {
        onSuccessCustom(data);
        window.history.back();
        refresh();
    };

    return (
        <Edit
            {...props}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <TextInput
                    source='name'
                    label="Название"
                    validate={required()}
                />

                <TextInput
                    source='slug'
                    label="Slug"
                    disabled
                />
    
                <ReferenceInput
                    label="Группа блога"
                    source="blogGroupSlug"
                    reference={CollectionsRus.BlogGroups}
                    filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                    validate={required()}
                >
                    <AutocompleteInput source='name' />
                </ReferenceInput>
    
                <ReferenceInput
                    label="Категория"
                    source="categorySlug"
                    reference={CollectionsRus.Categories}
                    allowEmpty
                    filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                >
                    <AutocompleteInput source='name' />
                </ReferenceInput>
    
                <NumberInput
                    source='timeToRead'
                    label="Время чтения (мин)"
                    validate={[ required(), number(), minValue(1) ]}
                />
    
                <RichTextInput
                    source='text'
                    label="Текст блога"
                    validate={required()}
                />
    
                <ImageInput
                    source="cover"
                    label="Изображение"
                    accept="image/*"
                    placeholder={<p>Перенесите изображения</p>}
                    validate={required()}
                >
                    <ImageField source="image" title="title" />
                </ImageInput>
    
                <EditableImage
                    {...props}
                    isRequired={true}
                    source="preview"
                    disableAspect={true}
                    isEditMode
                />
        
                {/* <NumberInput
                    disabled
                    source='views'
                    label="Просмотры"
                    defaultValue={0}
                    validate={required()}
                /> */}
    
                <SelectInput
                    source='themeIcon'
                    label="Значок темы"
                    choices={icons}
                    validate={required()}
                />
    
                <FormDataConsumer>
                    {({ formData, record, }: any) => (
                        <BooleanInput
                            label="Удалён"
                            source="isDeleted"
                            defaultValue={false}
                            disabled={
                                (formData.blogGroupSlug ?? '') !== (record.blogGroupSlug ?? '')
                            }
                        />
                    )}
                </FormDataConsumer>
    
                <TextInput
                    source='seoTitle'
                    label="SEO Заголовок"
                    validate={required()}
                />
    
                <TextInput
                    source='seoDescription'
                    label="SEO описание"
                    validate={required()}
                />
            </SimpleForm>
        </Edit>
    );
};

export default BlogsEdit;