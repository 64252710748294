import {
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

import Parse from 'parse';
import { OverrideDataProvider } from '@interfaces/global';

import staticImplements from '@utils/staticImplements';
import { Order } from '@interfaces/orders';
import { Collections } from '@enum/enum';
import getPaginationData from '@utils/pagination';
import { getPaymentMethdod, getShippingMethdod } from '@utils/index';
import formatPhoneNumber from '@utils/phoneUtil';
import ParseHelper from './ParseHelper';

const attributes: Array<keyof Order> = [
    'id',
    'user',
    'status',
    'orderDate',
    'shippingInfo',
    'paymentInfo',
    'productFromOrder',
    'finalPaymentInfo',
    'comment',
    'createOrderInApiShip',
    'orderNumber',
    'apiShipData',
    'apiShipDescription'
];

@staticImplements<Pick<OverrideDataProvider<Order>, 'getList' | 'update' | 'getOne'>>()
export default class OrdersClass {
    static async getList(
        _: string,
        { pagination, filter, sort, }: GetListParams
    ): Promise<GetListResult<Order>> {
        let ordersQuery = new Parse.Query(Collections.Orders).descending('orderDate');

        const { snm, phone, status, } = filter ?? {};

        if (snm) {
            ordersQuery = ParseHelper.GetQueryForSearch('user.snm', snm, ordersQuery);
        }

        if (phone) {
            ordersQuery = ParseHelper.GetQueryForSearch('user.phone', formatPhoneNumber(phone), ordersQuery);
        }

        if (status) {
            ordersQuery = ordersQuery.equalTo('status', status);
        }

        ordersQuery = ParseHelper.GetSortQuery(ordersQuery, sort);

        const orders = (await ordersQuery.find())
            .map((order) => ({
                deliveryMethod: getShippingMethdod((order.attributes as Order).shippingInfo.method),
                paymentMethod: getPaymentMethdod((order.attributes as Order).paymentInfo.method),
                ...ParseHelper.ExtractAttributes<Order, keyof Order>(
                    order, attributes
                ),
            }));

        return getPaginationData<Order>(pagination, orders);
    }

    static async getOne(
        _: string,
        {
            id,
        }: GetOneParams
    ): Promise<GetOneResult<Order>> {
        const order = await ParseHelper.GetObject(id, Collections.Orders);

        return {
            data: {
                deliveryMethod: getShippingMethdod((order.attributes as Order).shippingInfo.method),
                paymentMethod: getPaymentMethdod((order.attributes as Order).paymentInfo.method),
                ...ParseHelper.ExtractAttributes<Order, keyof Order>(
                    order, attributes
                ),
            },
        };
    }

    static async update(
        _: string,
        { data, id, }: UpdateParams<Order>
    ): Promise<UpdateResult<Order>> {
        delete data.deliveryMethod;
        delete data.paymentMethod;

        return ParseHelper.UpdateObject(data, true, Collections.Orders, String(id));
    }
}
