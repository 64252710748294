export const extractParamsFromUrl = (
    url: string,
    extractedKey: string
) => {
    if (!extractedKey) {
        return null;
    }

    const searchParams = new URLSearchParams(url);
    return searchParams.get(extractedKey);
};

export const extractFilterData = (
    url: string,
    searchedKey?: string
) => {
    const extractedFilter = extractParamsFromUrl(url, 'filter');
    if (!extractedFilter) {
        return null;
    }

    const parsedFilter = JSON.parse(extractedFilter);
    if (!parsedFilter) {
        return null;
    }
    return searchedKey ? parsedFilter[searchedKey] : parsedFilter;
};

export const extreactCreateParams = () => {
    const action = 'create?';

    const {
        location: {
            href,
        },
    } = window;

    return href.slice(href.lastIndexOf(action) + action.length);
};

export const findAllSearchedParamsFromUrl = (
    extractedParams: string[], url: string
) => (
    extractedParams.reduce((
        result, filteredKey
    ) => {
        const filterValue = extractParamsFromUrl(
            url,
            filteredKey
        );

        result[filteredKey] = filterValue;
        return result;
    }, {} as Record<string, string | null>
    )
);
