import { FC, useEffect, useState } from 'react';
import {
    Datagrid,
    List,
    Filter,
    TextField,
    TextInput,
    ShowButton,
    EditButton,
    DeleteButton,
    ListProps,
    FilterProps,
    BooleanField,
    DateField,
    AutocompleteInput,
    ReferenceInput,
    ImageField,
    FunctionField,
    BulkDeleteButton,
    Pagination
} from 'react-admin';
// @ts-ignore
import { History } from 'history';

import {
    getCreateBtn
} from '@utils/index';
import { extractFilterData } from '@utils/workerWithUrl';

import { ProceedToSubcollection } from '@components/common/ProceedToSubcollection';
import { CollectionsRus } from '@enum/enum';
import { makeStyles } from '@mui/styles';
import UploadBulkFiles from '@components/common/BulkUploading/UploadBulkFiles';
import { styles } from '@constants/styles';
import useScroll from '@hooks/useScroll';
import { stylesForImage } from '@styles/index';
import { ListActions } from './components/topToolBar';

const CategoriesFilter = (props: Partial<FilterProps>) => {
    const scroll = useScroll();

    return  (
        <div style={styles(scroll) as React.CSSProperties}>
            <Filter {...props}>

                <TextInput label="Поиск" source="name" alwaysOn />

                <ReferenceInput
                    id='filter-brand'
                    label="Бренд"
                    source="brandId"
                    reference={CollectionsRus.Brands}
                    allowEmpty
                    filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                    alwaysOn
                >
                    <AutocompleteInput source='name' />
                </ReferenceInput>

                <ReferenceInput
                    id='filter-category'
                    label="Категория"
                    source="parentCategorySlug"
                    reference={CollectionsRus.Categories}
                    allowEmpty
                    filterToQuery={searchText => ({ name: searchText, resetPagination: true, })}
                    alwaysOn
                >
                    <AutocompleteInput source='name' />
                </ReferenceInput>
            </Filter>
        </div>
    );
};

const BulkActions = (props: any) => (
    <>
        <UploadBulkFiles
            props={props}
            type="uploadingDensity"
        />

        <UploadBulkFiles
            props={props}
            type="uploadingImages"
        />

        <UploadBulkFiles
            props={props}
            type="uploadFile"
            source="manual"
        />

        <BulkDeleteButton { ...props }/>
    </>
);

const ProductsPagination = (props: any) => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100 ]} {...props} />;

const ProductsList = (props: Partial<ListProps>) => {
    const styles = stylesForImage();

    const [ isExistParentCategory, setIsExistParentCategory ] = useState<boolean>(false);

    const hideOrShowCreateBtn = (
        action: 'show' | 'hide'
    ) => {
        const createBtn = getCreateBtn();

        if (createBtn) {
            if (action === 'hide') {
                createBtn.style.display = 'none';
            } else {
                createBtn.style.display = 'flex';
            }
        }
    };

    useEffect(() => {
        const refCategoryFromUrl = extractFilterData((
            props as { history: History }).history.location.search, 'parentCategory'
        );

        if (refCategoryFromUrl) {
            setIsExistParentCategory(true);
            hideOrShowCreateBtn('show');
        } else {
            setIsExistParentCategory(false);
            hideOrShowCreateBtn('hide');
        }
    }, [ window.location.href ]);

    return (
        <List
            {...props}
            pagination={<ProductsPagination />}
            aside={
                <>
                    {isExistParentCategory && (
                        <ProceedToSubcollection
                            history={(props as { history: History }).history}
                            direction="back"
                            prevField="parentCategory"
                            nextField=""
                            nameFilteredField="parentCategory"
                            nameReturnedField="backCategoryId"
                            prevCollection={CollectionsRus.Categories}
                        />
                    )}
                </>
            }
            bulkActionButtons={<BulkActions />}
            filters={<CategoriesFilter />}
            actions={
                <ListActions
                    btns={[ {
                        type: 'products',
                        nameBtn: 'Excel',
                    } ]}
                />
            }
        >
            <Datagrid>
                <FunctionField label='Изображение' render={({ photos, }: any) => {
                    if (photos[0]?.photo?.image) {
                        return (
                            <ImageField
                                className={styles.image}
                                label='Изображение'
                                source="previewImg"
                            />
                        );
                    }
                    return (
                        <ImageField
                            className={styles.image}
                            label='Изображение'
                            emptyText='Пусто'
                            source="placeholder"
                        />);
                }} />

                <TextField label='Имя товара' source="name" />

                <DateField label="Обновлён" source='updatedAt' showTime />

                <BooleanField label="Удалена" source="isDeleted" />

                <BooleanField label="Популярный" source="isPopular" />

                <ShowButton label="" />

                <EditButton label="" />

                <DeleteButton label="" />
            </Datagrid>
        </List>
    );
};

export default ProductsList;
