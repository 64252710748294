import Parse from 'parse';
import { Collections } from '@enum/enum';

export const uniqueMsg = 'Поле должно быть уникальное';

export const errMsg = 'Ошибка во время проверки уникальности';

export class UniqueField {
    tmpExistedCache: Record<string, { date: number, id: string }> = {};
    tmpUniqueCache: Record<string, { date: number, id: string }> = {};
    baseKey: string = '';

    constructor (private collection: Collections, private field: string) {
        this.baseKey = `${collection}-${field}-`;
    }

    private GetKey(value: any) {
        return `${this.baseKey}${value}`;
    }

    private GetEntity = async (value: any, id?: string) => {
        const entity = await (new Parse.Query(this.collection)
            .equalTo(this.field, value)
            .first()
        );

        const valueForSave = {
            date: Date.now(),
            id: entity?.id ?? '',
        };

        if (entity && entity.id !== id) {
            this.tmpExistedCache[this.GetKey(value)] = valueForSave;
            return uniqueMsg;
        }
        this.tmpUniqueCache[this.GetKey(value)] = valueForSave;
        return '';
    };

    private IsValueExitsInExistedValues = (key: string) => (
        (Date.now() - (this.tmpExistedCache[key]?.date ?? 0) < 1 * 1000 * 60)
    );

    private IsValueExitsInUniquedValues = (key: string) => (
        Date.now() - (this.tmpUniqueCache[key]?.date ?? 0) < 1 * 1000 * 60
    );

    IsValueUnique = async (
        value: any,
        id?: string
    ) => {
        try {
            const key = this.GetKey(value);

            if (this.IsValueExitsInExistedValues(key)) {
                if (this.tmpExistedCache[key]?.id === id) {
                    return '';
                }
                return uniqueMsg;
            }

            if (this.IsValueExitsInUniquedValues(key)) {
                return '';
            }
            return await this.GetEntity(value, id);
        } catch (error) {
            console.log(error);
            
            return errMsg;
        }
    };
}