import { FC } from 'react';
import { HardenerOrder } from '@interfaces/ral';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { ComponentsHardnerHeader } from './ComponentsHardnerHeader';

type HardnerTableProps = {
    hardner: HardenerOrder | null;
};

export const HardnerTable: FC<HardnerTableProps> = ({
    hardner,
}) => {
    if (!hardner) {
        return <></>;
    }

    const { hardnerData, cost: hardnerCost, totalWeight: hardenerWeight, } = hardner;

    return (
        <div>
            <Typography
                sx={{ flex: '1 1 100%', }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Отвердитель
            </Typography>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, }} aria-label="table with components">
                    <ComponentsHardnerHeader />

                    <TableBody>
                        {hardnerData && (
                            <TableRow
                                style={{ background: 'white', }}
                                key={hardnerData.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, }, }}
                            >
                                <TableCell component="th" scope="row">
                                    {hardnerData.name}
                                </TableCell>
                                <TableCell align="right">{hardnerData.sku}</TableCell>
                                <TableCell align="right">{hardnerCost}</TableCell>
                                <TableCell align="right">{hardenerWeight}</TableCell>
                                <TableCell align="right">{hardnerData.totalResidueWeight}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
