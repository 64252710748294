import { Extenstion, TypeExportedFiles } from '@interfaces/global';
import { Button } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { useRecordContext } from 'react-admin';
import getBlobFromBase64 from '@utils/blobUtils';
import { runCloud } from '@utils/run-cloud';
import { CloudFunctionName } from '@enum/parse-cloud';

type ExportXmlBtnProps = {
    type: TypeExportedFiles,
    ext: Extenstion,
    onDownload: () => void,
    onGenerateDownloadLink: (link: string) => void,
    onSetFileName: (name: string) => void,
};

const ExportXmlBtn: FC<ExportXmlBtnProps> = ({
    type,
    ext,
    onDownload,
    onGenerateDownloadLink,
    onSetFileName,
}) => {
    const record = useRecordContext();

    const getParamsCloud = () => {
        const {
            id,
        } = record;
    
        switch (type) {
        case 'orders-pdf':
        case 'orders-xml':
            return { orderId: String(id), };
        case 'ral-orders-xml':
            return { ralOrderId: id, };
        default:
            break;
        }
    };

    const getFileName = () => {    
        switch (type) {
        case 'orders-pdf':
        case 'orders-xml':
            const {
                orderNumber,
                user: {
                    firstName,
                    lastName,
                },
            } = record;
            return `order-${orderNumber}-${lastName}_${firstName}.${ext}`;
        case 'ral-orders-xml':
            const {
                id,
            } = record;
            return `ral-order-${id}.${ext}`;
        default:
            return `test.${ext}`;
        }
    };

    const exportOrderFile = async (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        let resultContent: string = '';

        const params = getParamsCloud();

        switch (type) {
        case 'orders-xml':
            const answerXML = await runCloud(CloudFunctionName.ExportOrderXml, params);
            if (typeof answerXML === 'string') {
                resultContent = answerXML;
            }
            break;
        case 'ral-orders-xml':
            const answerRalOrdersXML = await runCloud(CloudFunctionName.ExportXMLRalOrder, params);
            if (typeof answerRalOrdersXML === 'string') {
                resultContent = answerRalOrdersXML;
            }
            break;
        case 'orders-pdf':
            const answerPDF = await runCloud(CloudFunctionName.ExportOrderPdf, params);
            if (typeof answerPDF === 'string') {
                resultContent = answerPDF;
            }
            break;
        default:
            break;
        }

        if (resultContent) {
            const blob = ext === 'xml' ?
                new Blob([ resultContent ])
                : getBlobFromBase64(resultContent);

            const fileDownloadUrl = URL.createObjectURL(blob);

            onSetFileName(getFileName());
            onGenerateDownloadLink(fileDownloadUrl);

            onDownload();

            URL.revokeObjectURL(fileDownloadUrl);
            onGenerateDownloadLink('');
            onSetFileName('');
        }
    };

    return (
        <Button
            title={ext}
            onClick={exportOrderFile}
            variant="contained"
            color={type === 'orders-xml' ? 'success' : 'warning'}
        >{ext}</Button>
    );
};

export default ExportXmlBtn;
