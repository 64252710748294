import ConsultationClass from '@parse_entity_utils/consultation';

import { Consultation } from '@interfaces/entities';
import { OverrideDataProvider } from '@interfaces/global';

const ConsultationDataProvider: Pick<OverrideDataProvider<
Consultation>, 'update' | 'getList' | 'getOne' | 'delete' | 'deleteMany'
> = {
    getList: ConsultationClass.getList,
    getOne: ConsultationClass.getOne,
    update: ConsultationClass.update,
    delete: ConsultationClass.delete,
    deleteMany: ConsultationClass.deleteMany,
};

export default ConsultationDataProvider;
