export enum EMethodShipping {
    PKUP = 'PKUP',
    TAXI = 'TAXI',
    API_SHIP = 'API_SHIP',
}

export enum PaymentMethod {
    Credit = 'CRDT',
    Online = 'ONLN',
    OnDelivery = 'ONDL',
}

export enum OrderStatus {
    CRD = 'Активный',
    WFP = 'Ожидание платежа',
    PFS = 'Заказ принят',
    OIP = 'Заказ собирается',
    SIP = 'Заказ отправлен',
    CLR = 'Клиент не забрал заказ',
    OSP = 'Успешно оплачен',
    PFD = 'Неудачный платеж',
    CLD = 'Отменен',
    DEL = 'Вручен',
    RR = 'Готов к выдаче',
    OW = 'В пути',
    Err = 'Произошла ошибка',
    RET = 'Возврат',
}