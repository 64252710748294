import { ContainersData } from '@interfaces/ral';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FC } from 'react';
import { Typography } from '@mui/material';

type ContainerTableType = {
    containers: ContainersData;
    type: 'preparing' | 'delivering' | 'hardnes';
};

export const ContainerTable: FC<ContainerTableType> = ({
    containers,
    type,
}) => {
    const isExistContainers = Object.keys(containers).length > 0;

    const getTitle = () => {
        switch (type) {
        case 'preparing':
            return 'Контейнеры для подготовки';
        case 'delivering':
            return 'Контейнеры для доставки';
        case 'hardnes':
            return 'Контейнеры для отвердителя';
        default:
            return '';
        }
    };

    return (
        <div style={{ width: '100%', }}>
            <Typography
                sx={{ flex: '1 1 100%', }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {getTitle()}
            </Typography>

            {!isExistContainers && (
                <Typography
                    sx={{ flex: '1 1 100%', }}
                    id="tableTitle"
                    component="p"
                >
                    не подобраны
                </Typography>
            )}

            {isExistContainers && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, }} aria-label="table with components">
                        <TableHead>
                            <TableRow>
                                <TableCell>sku</TableCell>
                                <TableCell align="right">Название</TableCell>
                                <TableCell align="right">Количество&nbsp;(шт)</TableCell>
                                <TableCell align="right">Объём&nbsp;(л)</TableCell>
                                <TableCell align="right">Стоимость&nbsp;(руб)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(containers)
                                .sort(([ _, { count: countA, } ], [ __, { count: countB, } ]) => countB - countA)
                                .map(([ sku, { count, volume, cost, name, } ]) => (
                                    <TableRow
                                        key={sku}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0, }, }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {sku}
                                        </TableCell>
                                        <TableCell align="right">{name}</TableCell>
                                        <TableCell align="right">{count}</TableCell>
                                        <TableCell align="right">{volume}</TableCell>
                                        <TableCell align="right">{cost}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};
