import React, { FC } from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ShowProps,
    TextField,
    NumberField,
    BooleanField
} from 'react-admin';

const UsersShow: FC<ShowProps> = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Основная">
                <TextField source="id" label="ID" />

                <TextField source="firstName" label="Имя" />

                <TextField source="lastName" label="Фамилия" />

                <TextField source="middleName" label="Отчество" />

                <TextField source="email" label="Почта" />

                <TextField source="phone" label="Телефон" />

                <TextField source="cityName" label="Город" />

                <BooleanField source="isAllowCashOnDelivery" label="Разрешён Н/П" />
            </Tab>

            <Tab label="Заказы/Бонусы">
                <NumberField source="bonusAmount" label="Количество бонусов" />

                <NumberField source="orderNumber" label="Количество заказов" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default UsersShow;