import { Button, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { CreateButton, TopToolbar, downloadCSV } from 'react-admin';
import { Error } from '@mui/icons-material';
import ExportUtils from '@utils/ExportUtils';

type ActionTypes = 'users-csv' | 'users-xslt' | 'products';

type ListActionsProps = {
    btns: Array<{
        type: ActionTypes,
        nameBtn: string,
    }>
};

export const ListActions: FC<ListActionsProps> = ({
    btns,
}) => {
    const [ loader, setLoader ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>('');

    const handleClick = async (type: ActionTypes) => {
        setError('');

        setLoader(true);
        
        let result = null;
        switch (type) {
        case 'products':
            result = await ExportUtils.ExportProducts();
            break;
        case 'users-csv':
            result = await ExportUtils.ExportUsersCSV();
            break;
        case 'users-xslt':
            result = await ExportUtils.ExportUsersXSLT();
            break;
        default:
            break;
        }

        if (result) {
            setError(result);
        }
        setLoader(false);
    };

    return (
        <TopToolbar>
            <div style={{ display: 'flex', alignItems: 'center', }}>
                <CreateButton />

                {btns.map(({ nameBtn, type, }) => (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleClick(type)}
                        disabled={loader}
                        sx={{ marginLeft: '15px', }}
                    >Скачать {nameBtn}</Button>
                ))}

                {!!error.length && <Tooltip title={error}><Error color='error' /></Tooltip>}
            </div>
        </TopToolbar>
    );
};