import {
    Show,
    SimpleShowLayout,
    TextField,
    ShowProps,
    BooleanField,
    ImageField,
    ArrayField,
    Datagrid,
    NumberField,
    RichTextField,
    ReferenceField,
    DateField,
    FileField
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import { CollectionsRus } from '@enum/enum';

const styles: any = {
    image: { minWidth: '100%', },
};

type CategoryShowProps = {
    classes: object,
    permissions: any,
    props: Partial<ShowProps>,
};

const ProductShow = (withStyles as any)(styles)(({ classes, permissions, ...props }: CategoryShowProps) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />

            <TextField label="Название" source="name" />

            <RichTextField label="Описание" source="description" />

            {/* <TextField
                label="ID 1C"
                source="id_1c"
            /> */}
            <TextField
                label="Sku" source="sku"
            />

            <RichTextField label="Инструкция" source="manual" />

            <RichTextField label="Полезное" source="useful" />

            <RichTextField label="Рекомендации" source="recommendation" />

            <ReferenceField label="Бренд" source="brandId" reference={CollectionsRus.Brands}>
                <TextField source="name" />
            </ReferenceField>

            <NumberField label="Цена" source="price" />

            <NumberField label="Количество" source="totalAmount" />

            <NumberField label="Будет начислено бонусов %" source="bonusPercent" />

            <BooleanField label="В наличии" source="inStock" />

            <BooleanField label="На распродаже" source="isOnSale" />

            <NumberField label="Ширина" source="sizeX" />

            <NumberField label="Длина" source="sizeY" />

            <NumberField label="Высота" source="sizeZ" />

            <NumberField label="Вес" source="weight" />

            <NumberField label="Плотность" source="density" />

            <NumberField label="Вес единицы (кг)" source="singleWeight" />

            <NumberField label="Осталось (кг)" source="totalResidueWeight" />

            <RichTextField label="Meta описание" source="metaDescription" />

            <TextField label="Seo название" source="seoTitle" />

            <TextField label="Seo заголовок" source="seoHeader" />

            <TextField label="Seo описание" source="seoDescription" />

            <ArrayField label="Изображения товара" source="photos">
                <Datagrid>
                    <BooleanField label="Главное" source="isMain" />

                    <ImageField label="Изображение" classes={classes} source="photo.image"/>
                </Datagrid>
            </ArrayField>

            <FileField source="manual.src" title="manual.title" />

            <ArrayField label="Хлебная крошка" source="breadcrumbs">
                <Datagrid>
                    <TextField label="Название" source="name" />

                    <TextField label="Slug" source="slug" />
                </Datagrid>
            </ArrayField>

            <BooleanField label="Популярный" source="isPopular" />

            <BooleanField label="Опасный" source="isDangerous" />

            <BooleanField label="Удалён" source="isDeleted" />

            <DateField label="Обновлён" source='updatedAt' showTime />
        </SimpleShowLayout>
    </Show>
));

export default ProductShow;
