import { CollectionsRus } from '@enum/enum';
import {
    EditProps,
    SimpleShowLayout,
    Show,
    TextField,
    ReferenceField,
    BooleanField,
    FunctionField,
    Tab,
    TabbedShowLayout,
    ArrayField,
    Datagrid
} from 'react-admin';
import ScaleIcon from '@mui/icons-material/Scale';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { ContainerTable } from './ContainerTable';

const RALShow = (props: EditProps) => {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="Основное">
                    <TextField label="ID" source="id" />

                    <TextField
                        label='Наименование заказа'
                        source="orderName"
                    />

                    <TextField
                        label='Номер заказа'
                        source="orderNumber"
                    />

                    {/* <TextField
                        label='Идентификатор заказа'
                        source="orderIdentificator"
                    /> */}

                    <FunctionField
                        label="Стоимость"
                        render={({ cost, }: any) => (`${cost} руб.`)}
                    />

                    <ReferenceField label="RAL" source="ralCodeId" reference={CollectionsRus.RAL}>
                        <TextField label='Код' source="code" />
                    </ReferenceField>

                    <FunctionField
                        label="Объём | Масса"
                        render={() => (
                            <BooleanField
                                source="needWeight"
                                valueLabelTrue="Масса"
                                valueLabelFalse="Объём"
                                TrueIcon={ScaleIcon as any}
                                FalseIcon={FormatColorFillIcon as any}
                                looseValue={true}
                            />
                        )}
                    />

                    <FunctionField
                        label="Единиц"
                        render={({ needVolume, needWeight, }: any) => (
                            needWeight ? `${needWeight} кг` : `${needVolume} л`
                        )}
                    />

                    <TextField label="Общий объём (л)" source="totalVolume" />

                    <FunctionField
                        label="Контейнеры"
                        style={{ whiteSpace: 'pre', }}
                        render={({
                            containersForDelivering = {},
                            containersForPreparing = {},
                            containersForHardners = {},
                        }: any) => (
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <ContainerTable
                                    containers={containersForDelivering}
                                    type="delivering"
                                />

                                <ContainerTable
                                    containers={containersForPreparing}
                                    type="preparing"
                                />

                                <ContainerTable
                                    containers={containersForHardners}
                                    type="hardnes"
                                />
                            </div>
                        )}
                    />
                </Tab>

                <Tab label="Компоненты">
                    <ArrayField label="Товары с весами" source="components">
                        <Datagrid>
                            <TextField label="Название" source="name" />

                            <TextField label="Вес (кг)" source="needWeight" />

                            <TextField label="sku" source="sku" />

                            <TextField label="Стоимость (руб)" source="cost" />
                        </Datagrid>
                    </ArrayField>
                </Tab>

                <Tab label="Отвердитель">
                    <TextField label="Название" source="hardner.hardnerData.name" />

                    <TextField label="Вес (кг)" source="hardner.totalWeight" />

                    <TextField label="sku" source="hardner.hardnerData.sku" />

                    <TextField label="Стоимость (руб)" source="hardner.cost" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default RALShow;
