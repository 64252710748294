import { FC, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Typography, Button, CircularProgress } from '@mui/material';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { FunctionField, NumberField, TextField } from 'react-admin';
import { EMethodShipping, PaymentMethod } from '@enum/order';
// import awaiter from '@utils/awaiter';
import useDownload from '@hooks/useDownload';
import { Order } from '@interfaces/orders';
import { changeCreateOrderInApiShip, getCreateOrderInApiShip } from './services/orderInApiShip';
import { ModalOrder } from './components/modalOrder';
import OrderLabelsButton from './components/getLabelsBtn';
import { MyTooltip } from './components/tooltip';

interface OrdersAsideProps {
    id?: string;
}

export const OrdersAside: FC<OrdersAsideProps> = ({
    id,
}) => {
    const [ createOrderApiShip, setCreateOrderApiShip ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>('');
    const [ open, setOpen ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(false);

    const {
        IvisibleButton,
        onDownload,
        onGenerateDownloadLink,
    } = useDownload();

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    const changeOderApiShip = async () => {
        setError('');
        setLoading(true);

        const result = await changeCreateOrderInApiShip(id, !createOrderApiShip);
        handleCloseModal();

        setLoading(false);
        if (typeof result === 'boolean'){
            return setCreateOrderApiShip(prev => !prev);
        }

        return setError(result);
    };

    const getOrderApiShip = async () => {
        setError('');

        const result = await getCreateOrderInApiShip(id);

        if(typeof result === 'boolean'){
            return setCreateOrderApiShip(result);
        }

        return setError(result ? result : 'Ошибка получения данных');
    };

    useEffect(() => {
        getOrderApiShip();
    }, []);

    const getCreateDeleteButton = (
        createOrderInApiShip: boolean,
        deliveryMethod: EMethodShipping,
        paymentMethod: PaymentMethod,
        finalPaymentInfo: Order['finalPaymentInfo']
    ) => {
        const isDisabled = (
            !id || (paymentMethod === PaymentMethod.Online && finalPaymentInfo?.paymentStatus !== 'succeeded')
        );

        return (
            <>
                {deliveryMethod === EMethodShipping.API_SHIP && (
                    <>
                        <Button
                            style={{ width: '90%', }}
                            variant="contained"
                            onClick={handleOpenModal}
                            disabled={isDisabled}
                        >
                            {createOrderInApiShip ? 'Удалить из Api Ship' : 'Создать в Api Ship'}
                        </Button>

                        {isDisabled && (
                            <MyTooltip customMessage="Онлайн оплата не была проведена" />
                        )}
                    </>
                )}
            </>
        );
    };

    return (
        <Card sx={{ marginLeft: '20px', }}>
            <CardContent>
                <CardHeader
                    sx={{ textAlign: 'center', }}
                    title="Доставка"
                    avatar={<LocalShippingIcon />}
                />

                <Typography variant="h6" >Город</Typography>
                <TextField source="shippingInfo.city" />

                <Typography variant="h6">Адрес</Typography>
                <TextField source="shippingInfo.address" />

                <FunctionField render={({ shippingInfo: { method, }, }: any) => (
                    <>
                        {method === EMethodShipping.API_SHIP && (
                            <>
                                <Typography variant="h6">Почтовый код</Typography>
                                <TextField source="shippingInfo.postalCode" />

                                <Typography variant="h6">Поставщик</Typography>
                                <TextField source="shippingInfo.pvzProvider" />
                            </>
                        )}
                    </>
                )} />

                <Typography variant="h6">Способ доставки</Typography>
                <TextField source="deliveryMethod" />

                <Typography variant="h6">Общая стоимость товаров</Typography>
                <NumberField source="finalPaymentInfo.totalPrice" />

                <Typography variant="h6">Стоимость доставки</Typography>
                <NumberField source="finalPaymentInfo.deliveryPrice" />

                <FunctionField render={({ createOrderInApiShip, shippingInfo: { method, }, }: any) => (
                    <>
                        {method === EMethodShipping.API_SHIP && (
                            <Typography variant="h6">
                                {createOrderInApiShip ?
                                    'Заказ создан в Api Ship'
                                    : 'Заказа нет в Api Ship'
                                }
                            </Typography>
                        )}
                    </>
                )} />

                <FunctionField
                    style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', }}
                    render={({
                        createOrderInApiShip,
                        shippingInfo: { method: deliveryMethod, },
                        paymentInfo: { method: paymentMethod, },
                        finalPaymentInfo,
                    }: any) => (
                        <>
                            {getCreateDeleteButton(
                                createOrderInApiShip,
                                deliveryMethod,
                                paymentMethod,
                                finalPaymentInfo
                            )}

                            <OrderLabelsButton
                                onDownload={onDownload}
                                onGenerateDownloadLink={onGenerateDownloadLink}
                            />

                            <IvisibleButton />
                        </>
                    )} />

                {Boolean(error.length) && <Typography color="red">{error}</Typography>}

                <ModalOrder
                    loading={loading}
                    open={open}
                    handleCloseModal={handleCloseModal}
                    changeOderApiShip={changeOderApiShip}
                    createOrderApiShip={createOrderApiShip}
                />
            </CardContent>
        </Card>
    );
};
