import { OverrideDataProvider } from '@interfaces/global';
import { Ral, RalOrderCommon } from '@interfaces/ral';
import RalOrderClass from '@parse_entity_utils/ralOrder';

const RalOrderDataProvider: Pick<OverrideDataProvider<RalOrderCommon>, 'getOne' | 'getList' | 'update' | 'create'> = {
    getList: RalOrderClass.getList,
    getOne: RalOrderClass.getOne,
    update: RalOrderClass.update,
    create: RalOrderClass.create,
};

export default RalOrderDataProvider;
