import {
    Create,
    SimpleForm,
    TextInput,
    CreateProps,
    required
} from 'react-admin';

const TelegramCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Ссылка на последний пост"
                source="lastMessageLink"
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export default TelegramCreate;
