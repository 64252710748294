import { ListIndomixTypes } from '@constants/ral';
import { CollectionsRus } from '@enum/enum';
import {
    Datagrid,
    List,
    Filter,
    TextField,
    ListProps,
    FilterProps,
    TextInput,
    ReferenceField,
    SelectField
} from 'react-admin';

const RALFilter = (props: Partial<FilterProps>) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="code" alwaysOn />
    </Filter>
);

const RALList = (props: Partial<ListProps>) => (
    <List
        {...props}
        filters={<RALFilter />}
    >
        <Datagrid rowClick="edit">
            <TextField label='Название' source="name" />

            <TextField label='Код' source="code" />

            <SelectField
                label="Категория"
                source='category'
                choices={ListIndomixTypes}
            />
        </Datagrid>
    </List>
);

export default RALList;
