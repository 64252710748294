import React, { FC } from 'react';
import {
    ArrayField,
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    NumberField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import { OrdersAside } from './ordersAside';

const OrdersShow: FC<ShowProps> = (props) => (
    <Show aside={<OrdersAside id={props.id} />} {...props}>
        <TabbedShowLayout>
            <Tab label="Заказчик">
                <TextField label="Имя" source="user.firstName" />

                <TextField label="Фамилия" source="user.lastName" />

                <TextField label="Отчество" source="user.middleName" />

                <TextField label="Телефон" source="user.phone" />

                <TextField label="Почта" source="user.email" />

                <TextField label="Комментарий к заказу" source="comment" />
            </Tab>

            <Tab label="Заказ">
                <TextField label="№" source="orderNumber" />

                <TextField label="Статус заказа" source="status" />

                <DateField label="Дата заказа" source="orderDate" showTime={true} />

                <TextField label="Способ доставки" source="deliveryMethod" />

                <TextField label="Способ оплаты" source="paymentMethod" />

                <BooleanField label="Оплачен бонусами" source="paymentInfo.useBonus" />

                <FunctionField label="ID заказа в API SHIP" render={({ apiShipData, }: any) => (
                    <>
                        {apiShipData?.apiShipOrderId && (
                            <TextField label="Цена" emptyText={apiShipData?.apiShipOrderId ?? ''}  />
                        )}

                        {!apiShipData?.apiShipOrderId && (
                            <TextField label="Цена" emptyText={'Заказ отсутсвует в api ship'}  />
                        )}
                    </>
                )} />

                <FunctionField label="Код отслеживания заказа" render={({ apiShipData, }: any) => (
                    <>
                        {apiShipData?.apiShipOrderId && (
                            <TextField label="Код" emptyText={apiShipData?.trackingCode ?? ''}  />
                        )}

                        {!apiShipData?.apiShipOrderId && (
                            <TextField label="Код" emptyText={'Заказ отсутсвует в api ship'}  />
                        )}
                    </>
                )} />
            </Tab>

            <Tab label="Состав заказа">
                <ArrayField label="Состав" source="productFromOrder" fieldKey="name">
                    <Datagrid>
                        <TextField label="Название товара" source="name" />

                        <NumberField label="Количество" source="amount" />

                        <FunctionField label="Цена за шт" render={({ price, }: any) => (
                            <>
                                <TextField label="Цена" emptyText={String(Number(price).toFixed(2))}  />
                            </>
                        )} />

                        <FunctionField label="Цена" render={({ amount, price, }: any) => (
                            <>
                                <TextField label="Цена" emptyText={String(Number(amount * price).toFixed(2))}  />
                            </>
                        )} />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default OrdersShow;