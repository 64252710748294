import { FC } from 'react';
import { Button, useRecordContext } from 'react-admin';

import ContentAdd from '@material-ui/icons/Add';

import { Category } from '@interfaces/entities';
import { CollectionsRus } from '@enum/enum';

const AddSubcategoryIcon: FC<{}> = () => {
    const {
        id,
    } = useRecordContext() as Category;

    return (
        <Button
            onClick={() => {
                const currentLocation = decodeURI(window.location.href);

                window.location.href = [
                    `${currentLocation.slice(
                        0,
                        currentLocation.indexOf(CollectionsRus.Categories) + CollectionsRus.Categories.length
                    )}`,
                    `/create?refCategory=${id}`
                ].join('');
            }}
            variant="outlined"
        >
            <ContentAdd />
        </Button>
    );
};

export default AddSubcategoryIcon;
