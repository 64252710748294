import React, { FC } from 'react';
import { EditableImage } from '@components/common/editableImage/EditableImage';
import { CollectionsRus } from '@enum/enum';
import useCustomNotify from '@hooks/useNotify';
import RichTextInput from 'ra-input-rich-text';
import { Edit, EditProps, required, SimpleForm, TextInput, useRefresh } from 'react-admin';

const CompilationEdit: FC<EditProps> = (props) => {
    const refresh = useRefresh();
    const {
        onSuccess: onSuccessCustom,
        onFailure,
    } = useCustomNotify(CollectionsRus.Compilation, 'edit', 'update');

    const onSuccess = (data: any) => {
        onSuccessCustom(data);
        window.history.back();
        refresh();
    };

    return (
        <Edit
            {...props}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <TextInput
                    source='title'
                    label="Название"
                    validate={required()}
                />

                <TextInput
                    source='link'
                    label="Ссылка"
                    validate={required()}
                />
    
                <RichTextInput
                    source='description'
                    label="Описание"
                    validate={required()}
                />
    
                <EditableImage
                    {...props}
                    isRequired={true}
                    source="preview"
                    disableAspect={true}
                />
            </SimpleForm>
        </Edit>
    );
};

export default CompilationEdit;