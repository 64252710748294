import { useState } from 'react';

type Callback<Result> = () => Promise<Result> | Result;

export const useLoading = (initialValue = true) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(initialValue);

    const startLoading = () => {
        setIsLoading(true);
    };

    const endLoading = () => {
        setIsLoading(false);
    };

    const callWithLoading = async <CbResult>(callback: Callback<CbResult>): Promise<CbResult | null> => {
        startLoading();

        try {
            const result = await callback();

            endLoading();

            return result;
        } catch {
            endLoading();

            return null;
        }
    };

    return {
        isLoading,
        startLoading,
        endLoading,
        callWithLoading,
    };
};
