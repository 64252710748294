import React, { FC } from 'react';
import { EditableImage } from '@components/common/editableImage/EditableImage';
import { CollectionsRus } from '@enum/enum';
import useCustomNotify from '@hooks/useNotify';
import RichTextInput from 'ra-input-rich-text';
import { Create, CreateProps, SimpleForm, TextInput, required } from 'react-admin';

export const CompilationCreate: FC<CreateProps> = (props) => {
    const {
        onSuccess,
        onFailure,
    } = useCustomNotify(CollectionsRus.Compilation, 'edit', 'create');

    return (
        <Create
            {...props}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm>
                <TextInput
                    source='title'
                    label="Название"
                    validate={required()}
                />

                <TextInput
                    source='link'
                    label="Ссылка"
                    validate={required()}
                />
    
                <RichTextInput
                    source='description'
                    label="Описание"
                    validate={required()}
                />
    
                <EditableImage
                    {...props}
                    isRequired={true}
                    source="preview"
                    disableAspect={true}
                />
            </SimpleForm>
        </Create>
    );
};

export default CompilationCreate;