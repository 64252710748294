import ExportBtn from '@components/common/ExportBtn';
import { CollectionsRus } from '@enum/enum';
import useDownload from '@hooks/useDownload';
import {
    Datagrid,
    List,
    Filter,
    TextField,
    ListProps,
    FilterProps,
    TextInput,
    ReferenceField,
    SelectField
} from 'react-admin';

const RALFilter = (props: Partial<FilterProps>) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="ral" alwaysOn />
    </Filter>
);

const RALList = (props: Partial<ListProps>) => {
    const {
        IvisibleButton,
        onDownload,
        onGenerateDownloadLink,
        onSetFileName,
    } = useDownload();

    return (
        <List
            {...props}
            filters={<RALFilter />}
        >
            <Datagrid rowClick="show">
                <TextField label='Наименование заказа' source="orderName" />

                <TextField label='Номер заказа' source="orderNumber" />
                {/* <TextField label='Идентификатор заказа' source="orderIdentificator" /> */}
    
                <ReferenceField label="RAL" source="ralCodeId" reference={CollectionsRus.RAL}>
                    <TextField label='Код' source="code" />
                </ReferenceField>
    
                <TextField label='Стоимость' source="cost" />
    
                <ExportBtn
                    type='ral-orders-xml'
                    onGenerateDownloadLink={onGenerateDownloadLink}
                    onDownload={onDownload}
                    onSetFileName={onSetFileName}
                    ext="xml"
                />

                <IvisibleButton />
            </Datagrid>
        </List>
    );
};

export default RALList;
