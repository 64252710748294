import React, { FC } from 'react';
import {
    Edit,
    EditProps,
    SimpleForm, TextInput,
    required,
    NumberInput,
    BooleanInput
} from 'react-admin';

const UsersEdit: FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source='username' label="Имя пользователя" validate={required()} />

            <TextInput source='firstName' label="Имя" validate={required()} />

            <TextInput source='lastName' label="Фамилия" validate={required()} />

            <TextInput source='middleName' label="Отчество" validate={required()} />

            <TextInput source='email' label="Почта" validate={required()} />

            <TextInput source='phone' label="Телефон" validate={required()} />

            <NumberInput source='bonusAmount' label='Количество бонусов' validate={required()} />

            <NumberInput source='orderNumber' label='Количество заказов' disabled />

            <BooleanInput source='isAllowCashOnDelivery' label='Разрешён Н/П' />
        </SimpleForm>
    </Edit>
);

export default UsersEdit;